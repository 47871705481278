import { Component } from '@angular/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.page.html'
})
export class LegalPage {

  constructor() { }
}
