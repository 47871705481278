import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-remove-modal',
  templateUrl: './remove-modal.component.html',
})
export class RemoveModalComponent {

  constructor(private modalController: ModalController) { }

  onSubmit() {
    this.modalController.dismiss({
      success: true
    });
  }

  onDismiss() {
    this.modalController.dismiss({
      success: false
    });
  }

}
