import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AccessibilityPage } from './pages/accessibility/accessibility.page';
import { FactsPage } from './pages/facts/facts.page';
import { LegalPage } from './pages/legal/legal.page';
import { LoginPage } from './pages/login/login.page';
import { NetworksPage } from './pages/networks/networks.page';
import { UsersPage } from './pages/users/users.page';

const routes: Routes = [
  {
    path: '',
    data: {
      breadcrumb: 'Gestion'
    },
    children: [
      {
        path: '',
        component: LoginPage
      },
      {
        path: 'faits',
        component: FactsPage,
        data: {
          breadcrumb: 'Faits'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'utilisateurs',
        component: UsersPage,
        data: {
          breadcrumb: 'Utilisateurs'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'reseaux',
        component: NetworksPage,
        data: {
          breadcrumb: 'Réseaux'
        },
        canActivate: [AuthGuard]
      },
      {
        path: 'mentions-legales',
        component: LegalPage,
        data: {
          breadcrumb: `Mentions légales`
        },
      },
       {
        path: 'accessibilite',
        component: AccessibilityPage,
        data: {
          breadcrumb: `Accessibilité`
        },
      },
      {
        path: '**',
        redirectTo: ''
      },
    ]
  },
  {
    path: 'facts',
    loadChildren: () => import('./pages/facts/facts.module').then( m => m.FactsPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
