export class FactGood {
  id: string;
  quality: string;
  type: string;

  constructor(obj: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }
}
