<ion-content>
  <app-header></app-header>
  <main role="main" class="">
    <app-breadcrumb></app-breadcrumb>
    <div class="fr-container">
      <div class="fr-grid-row overflow-hidden">
        <div id="content" class="fr-col-12 fr-pl-4v">
          <div class="fr-container--fluid fr-mt-2w">

            <h3 class="fr-text--lead fr-mb-4v">Accessibilité</h3>
            <p class="fr-text--xs fr-mb-2w">
              Le Bureau de la sûreté des transports ferroviaires et collectifs (DGITM/DTFFP/SFG4) du Ministère de la Transition
              écologique et de la Cohésion des territoires s'engage à rendre son service accessible, conformément à l'article 47 de la
              loi n° 2005-102 du 11 février 2005. Cette déclaration d'accessibilité s'applique à l'application App'ISIS.
            </p>

            <h4 class="fr-text--lg fr-mb-1w">État de conformité</h4>
            <p class="fr-text--xs fr-mb-2w">
              L'application App'ISIS est non conforme avec le référentiel général d'amélioration de l'accessibilité pour les
              administrations (RGAA), car il n'existe aucun résultat d'audit en cours de validité permettant de mesurer le respect des
              critères. Les corrections des non-conformités sont en cours et un audit sera établi afin d'appliquer les corrections
              nécessaires pour améliorer l'accessibilité au niveau fonctionnel. Aussi, la présente déclaration d'accessibilité sera
              mise à jour au plus vite.
            </p>

            <h5 class="fr-text--md fr-mb-1w">Résultat des tests</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l'absence d'audit de conformité il n'y a pas de résultats de tests.
            </p>

            <h4 class="fr-text--lg fr-mb-1w">Contenus non accessibles</h4>

            <h5 class="fr-text--md fr-mb-1w">Non-conformité</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l'absence d'audit tous les contenus seront considérés comme non accessibles par hypothèse.
            </p>

            <h5 class="fr-text--md fr-mb-1w">Dérogations pour charge disproportionnée</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l'absence d'audit aucune dérogation n'a été établie.
            </p>

            <h5 class="fr-text--md fr-mb-1w">Contenus non soumis à l’obligation d’accessibilité</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l’absence d’audit aucun contenu n’a été identifié comme n’entrant pas dans le champ de la législation applicable.
            </p>

            <h5 class="fr-text--md fr-mb-1w">Technologies utilisées pour la réalisation de l’application App’ISIS</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l'absence d'audit aucune technologie n'a été utilisée.
            </p>

            <h5 class="fr-text--md fr-mb-1w">Agents utilisateurs, technologies d’assistance et outils utilisés pour vérifier l’accessibilité</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l’absence d’audit aucun agent utilisateur et aucune technologie d’assistance n’ont été utilisés.
            </p>

            <h5 class="fr-text--md fr-mb-1w">Les tests des pages web ont été effectués avec les combinaisons de navigateurs web et lecteurs d’écran suivants</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l’absence d’audit aucune combinaison de navigateur et de lecteur d’écran n’a été utilisée.
            </p>

            <h5 class="fr-text--md fr-mb-1w">Les outils suivants ont été utilisés lors de l’évaluation</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l’absence d’audit aucun outil n’a été utilisé lors de l’évaluation.
            </p>

            <h5 class="fr-text--md fr-mb-1w">Pages du site ayant fait l’objet de la vérification de conformité</h5>
            <p class="fr-text--xs fr-mb-2w">
              En l’absence d’audit aucune page n’a fait l’objet de la vérification de conformité.
            </p>

            <h4 class="fr-text--lg fr-mb-1w">Amélioration et contacts</h4>
            <p class="fr-text--xs fr-mb-2w">
              Si vous avez signalé au responsable de l’application App’ISIS un défaut d’accessibilité qui vous empêche d’accéder à un
              contenu ou à un des services du portail et vous n’avez pas obtenu de réponse satisfaisante.
            </p>
            <p class="fr-text--xs fr-mb-0">
              Écrire un message au Défenseur des droits : <a href="https://formulaire.defenseurdesdroits.fr/">https://formulaire.defenseurdesdroits.fr/</a>
              <br />
              Contacter le délégué du Défenseur des droits dans votre région : <a href="https://www.defenseurdesdroits.fr/saisir/delegues">https://www.defenseurdesdroits.fr/saisir/delegues</a>
              <br />
              Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) :
            </p>
            <p class="fr-text--xs fr-mb-2w fr-text--center">
              Défenseur des droits
              <br />Libre réponse 71120
              <br />75342 Paris CEDEX 07
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</ion-content>