import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/core/services/storage.service';
import { User } from 'src/app/store/user/user.model';
import { FactPerson } from 'src/app/store/fact/fact-person.model';

@Component({
  selector: 'app-add-victim-modal',
  templateUrl: './add-victim-modal.component.html',
})
export class AddVictimModalComponent implements OnInit {

  createForm: UntypedFormGroup;
  isSubmitted = false;
  currentUser: User;
  isAgent = false;
  isOther = false;

  constructor(
    private modalController: ModalController,
    private storageService: StorageService,
    private formBuilder: UntypedFormBuilder) {
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
   
    this.createForm = this.formBuilder.group({
      quality: ['Voyageur', [
        Validators.required,
      ]],
      function: ['Inconnu', [
        Validators.required,
      ]],
      gender: ['Inconnu', [
        Validators.required,
      ]],
      count: [1, [
        Validators.required,
        Validators.min(1),
      ]],
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.createForm.valid) {
      return false;
    }

    const person = new FactPerson({
      id: Math.random().toString(36).substring(2,10), // NOSONAR
      type: 'Victime',
      quality: this.createForm.controls.quality.value,
      function: this.createForm.controls.function.value,
      gender: this.createForm.controls.gender.value,
      count: this.createForm.controls.count.value,
      incapacity: 0,
    })

    this.modalController.dismiss(person);
  }

  onChangeQuality(quality) {
    this.isAgent = quality === 'Agent';
    this.isOther = quality === 'Autre';
    if (this.isAgent) {
      this.createForm.controls.function.setValue('Inconnu');
    }
  }

  onDismiss() {
    this.modalController.dismiss();
  }

}
