import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { deleteFact } from 'src/app/store/fact/fact.actions';
import { Fact } from 'src/app/store/fact/fact.model';

@Component({
  selector: 'app-fact-delete',
  templateUrl: './fact-delete.component.html',
})
export class FactDeleteComponent {

  @Input() fact: Fact;
  
  constructor(
    private store: Store<AppState>,
    private modalController: ModalController) {
  }

  onSubmit() {
    this.store.dispatch(deleteFact(this.fact));
    this.modalController.dismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }
}
