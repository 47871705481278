import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LoginPageRoutingModule } from './login-routing.module';

import { LoginPage } from './login.page';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { UsersPageModule } from '../users/users.module';
import { NetworksPageModule } from '../networks/networks.module';
import { FactsPageModule } from '../facts/facts.module';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    UsersPageModule,
    NetworksPageModule,
    FactsPageModule,
    IonicModule,
    LoginPageRoutingModule,
    SharedModule,
    ReactiveFormsModule
  ],
  declarations: [LoginPage]
})
export class LoginPageModule {}
