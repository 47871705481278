<ion-content>
  <div class="inner-content">
    <div class="fr-modal__body">
      <div class="fr-modal__header"></div>
      <div class="fr-modal__content">
        <h1 id="fr-modal-2-title" class="fr-modal__title">
          Création d'un fait
        </h1>
        <form *ngIf="createForm" [formGroup]="createForm" novalidate>

          <h3 class="fr-text--lg fr-mb-4v">Date et heure</h3>
          <div class="fr-grid-row fr-mb-8v">
            <div class="fr-col-6">
              <div class="fr-input-group fr-mr-1v">
                <label class="fr-label" for="date">Date</label>
                <input class="fr-input" type="date" id="date" name="date" formControlName="date">
                <p *ngIf="isSubmitted && createForm.controls?.date?.errors?.required" class="fr-error-text">
                  La date du fait est obligatoire.
                </p>
              </div>
            </div>
            <div class="fr-col-6">
              <div class="fr-input-group fr-ml-1v">
                <label class="fr-label" for="time">Heure</label>
                <input class="fr-input" type="time" id="time" name="time" formControlName="time">
                <p *ngIf="isSubmitted && createForm.controls?.time?.errors?.required" class="fr-error-text">
                  L'heure du fait est obligatoire.
                </p>
              </div>
            </div>
          </div>
  
          <h3 class="fr-text--lg fr-mb-4v">Lieu</h3>

          <div class="fr-select-group">
            <label class="fr-label" for="networkId">Réseau</label>
            <select (change)="onChangeNetwork($event.target.value)" class="fr-select" id="networkId" name="networkId"
              formControlName="networkId">
              <option value="" selected disabled hidden>Sélectionnez un réseau</option>
              <option *ngFor="let network of networks;" [value]="network.id">
                {{ network.name }}
              </option>
            </select>
            <p *ngIf="isSubmitted && createForm.controls?.networkId?.errors?.required" class="fr-error-text">
              Le réseau est obligatoire.
            </p>
          </div>

          <div class="fr-grid-row fr-mb-8v">
            <div class="fr-col-6">
            <div [hidden]="!towns" class="fr-select-group fr-mr-1v">
              <label class="fr-label" for="town">Commune</label>
              <select class="fr-select" id="town" name="town" formControlName="town">
                <option *ngFor="let town of towns;" [value]="town.value">
                  {{ town.name }}
                </option>
              </select>
              <p *ngIf="isSubmitted && createForm.controls?.town?.errors?.required" class="fr-error-text">
                La commune est obligatoire.
              </p>
            </div>
            </div>
            <div class="fr-col-6">
              <div [hidden]="!transports" class="fr-select-group fr-ml-1v">
                <label class="fr-label" for="transport">Mode de transport</label>
                <select class="fr-select" id="transport" name="transport" formControlName="transport">
                  <option *ngFor="let transport of transports;" [value]="transport">
                    {{ transport }}
                  </option>
                </select>
                <p *ngIf="isSubmitted && createForm.controls?.town?.errors?.required" class="fr-error-text">
                  Le mode de transport est obligatoire.
                </p>
              </div>
            </div>
          </div>
  
          <div class="fr-grid-row fr-mb-8v">
            <div class="fr-col-6">
              <div class="fr-input-group fr-mr-1v">
                <label class="fr-label" for="stop">Station / Arrêt</label>
                <input class="fr-input" type="text" id="stop" name="stop" formControlName="stop">
              </div>
            </div>
            <div class="fr-col-6">
              <div class="fr-input-group fr-ml-1v">
                <label class="fr-label" for="line">Ligne</label>
                <input class="fr-input" type="text" id="line" name="line" formControlName="line">
              </div>
            </div>
          </div>
  
          <div class="fr-form-group">
            <fieldset class="fr-fieldset fr-fieldset--inline">
              <legend class="fr-fieldset__legend fr-text--regular">
                Zone de securité prioritaire
              </legend>
              <div class="fr-fieldset__content">
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="priorityZone-1" name="priorityZone" formControlName="priorityZone"
                    [value]="true">
                  <label class="fr-label" for="priorityZone-1">Oui</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="priorityZone-2" name="priorityZone" formControlName="priorityZone"
                    [value]="false">
                  <label class="fr-label" for="priorityZone-2">Non</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="priorityZone-3" name="priorityZone" formControlName="priorityZone"
                  [value]="null">
                  <label class="fr-label" for="priorityZone-3">Je ne sais pas</label>
                </div>
              </div>
            </fieldset>
          </div>
  
          <h3 class="fr-text--lg fr-mb-4v">Fait</h3>
  
          <div class="fr-select-group">
            <label class="fr-label" for="nomenclature">
              Type de fait*
            </label>
            <ng-select [items]="nomenclatures" bindLabel="label" bindValue="type" dropdownPosition="auto" 
              formControlName="nomenclature" [clearable]="false" [searchable]="false">
              <ng-template ng-option-tmp let-item="item">
                <div>
                  <span>{{ item.type }} - {{ item.label }}</span>
                </div>
                <small class="fr-hint-text ws">{{ item.example }}</small>
              </ng-template>
            </ng-select>
            <p *ngIf="isSubmitted && createForm.controls?.nomenclature?.errors?.required" class="fr-error-text">
              Le type de fait est obligatoire.
            </p>
          </div>

          <h3 class="fr-text--lg fr-mb-4v">Conséquences et moyen</h3>
          
          <div class="fr-form-group fr-mb-3w">
            <fieldset class="fr-fieldset fr-fieldset--inline">
              <legend class="fr-fieldset__legend fr-text--regular">
                Impact sur le service*
              </legend>
              <div class="fr-fieldset__content">
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="serviceImpact-2" name="serviceImpact" formControlName="serviceImpact" [value]="true">
                  <label class="fr-label" for="serviceImpact-2">Oui</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="serviceImpact-3" name="serviceImpact" formControlName="serviceImpact" [value]="false">
                  <label class="fr-label" for="serviceImpact-3">Non</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="serviceImpact-1" name="serviceImpact" formControlName="serviceImpact" [value]="null">
                  <label class="fr-label" for="serviceImpact-1">Je ne sais pas</label>
                </div>
              </div>
            </fieldset>
          </div>
          
          <div class="fr-form-group fr-mb-3w">
            <fieldset class="fr-fieldset fr-fieldset--inline">
              <legend class="fr-fieldset__legend fr-text--regular">
                Modification de l'offre*
              </legend>
              <div class="fr-fieldset__content">
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="offerModification-2" name="offerModification" formControlName="offerModification"
                    [value]="true">
                  <label class="fr-label" for="offerModification-2">Oui</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="offerModification-3" name="offerModification" formControlName="offerModification"
                    [value]="false">
                  <label class="fr-label" for="offerModification-3">Non</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="offerModification-1" name="offerModification" formControlName="offerModification"
                    [value]="null">
                  <label class="fr-label" for="offerModification-1">Je ne sais pas</label>
                </div>
              </div>
            </fieldset>
          </div>
          
          <div class="fr-form-group fr-mb-3w">
            <fieldset class="fr-fieldset fr-fieldset--inline">
              <legend class="fr-fieldset__legend fr-text--regular">
                Intervention des services internes*
              </legend>
              <div class="fr-fieldset__content">
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="departmentIntervention-2" name="departmentIntervention"
                    formControlName="departmentIntervention" [value]="true">
                  <label class="fr-label" for="departmentIntervention-2">Oui</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="departmentIntervention-3" name="departmentIntervention"
                    formControlName="departmentIntervention" [value]="false">
                  <label class="fr-label" for="departmentIntervention-3">Non</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="departmentIntervention-1" name="departmentIntervention"
                    formControlName="departmentIntervention" [value]="null">
                  <label class="fr-label" for="departmentIntervention-1">Je ne sais pas</label>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="fr-form-group fr-mb-3w">
            <fieldset class="fr-fieldset fr-fieldset--inline">
              <legend class="fr-fieldset__legend fr-text--regular">
                Intervention des forces de l'ordre*
              </legend>
              <div class="fr-fieldset__content">
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="policeIntervention-2" name="policeIntervention" formControlName="policeIntervention"
                    [value]="true">
                  <label class="fr-label" for="policeIntervention-2">Oui</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="policeIntervention-3" name="policeIntervention" formControlName="policeIntervention"
                    [value]="false">
                  <label class="fr-label" for="policeIntervention-3">Non</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="policeIntervention-1" name="policeIntervention" formControlName="policeIntervention"
                    [value]="null">
                  <label class="fr-label" for="policeIntervention-1">Je ne sais pas</label>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="fr-form-group fr-mb-3w">
            <fieldset class="fr-fieldset fr-fieldset--inline">
              <legend class="fr-fieldset__legend fr-text--regular">
                Intervention des secours*
              </legend>
              <div class="fr-fieldset__content">
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="emergencyIntervention-2" name="emergencyIntervention"
                    formControlName="emergencyIntervention" [value]="true">
                  <label class="fr-label" for="emergencyIntervention-2">Oui</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="emergencyIntervention-3" name="emergencyIntervention"
                    formControlName="emergencyIntervention" [value]="false">
                  <label class="fr-label" for="emergencyIntervention-3">Non</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="emergencyIntervention-1" name="emergencyIntervention"
                    formControlName="emergencyIntervention" [value]="null">
                  <label class="fr-label" for="emergencyIntervention-1">Je ne sais pas</label>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="fr-form-group fr-mb-3w">
            <fieldset class="fr-fieldset fr-fieldset--inline">
              <legend class="fr-fieldset__legend fr-text--regular">
                Dépôt d'une plainte*
              </legend>
              <div class="fr-fieldset__content">
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="complaint-2" name="complaint" formControlName="complaint" [value]="true">
                  <label class="fr-label" for="complaint-2">Oui</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="complaint-3" name="complaint" formControlName="complaint" [value]="false">
                  <label class="fr-label" for="complaint-3">Non</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="complaint-1" name="complaint" formControlName="complaint" [value]="null">
                  <label class="fr-label" for="complaint-1">Je ne sais pas</label>
                </div>
              </div>
            </fieldset>
          </div>
          <div class="fr-form-group fr-mb-3w">
            <fieldset class="fr-fieldset fr-fieldset--inline">
              <legend class="fr-fieldset__legend fr-text--regular">
                Fait commis avec une arme*
              </legend>
              <div class="fr-fieldset__content">
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="weapon-2" name="weapon" formControlName="weapon" [value]="true">
                  <label class="fr-label" for="weapon-2">Oui</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="weapon-3" name="weapon" formControlName="weapon" [value]="false">
                  <label class="fr-label" for="weapon-3">Non</label>
                </div>
                <div class="fr-radio-group fr-radio-group--sm">
                  <input type="radio" id="weapon-1" name="weapon" formControlName="weapon" [value]="null">
                  <label class="fr-label" for="weapon-1">Je ne sais pas</label>
                </div>
              </div>
            </fieldset>
          </div>
          
          <h3 class="fr-text--lg fr-mb-4v">Cause (1 réponse possible)</h3>
          <div class="fr-select-group fr-mb-8v">
            <label class="fr-label" for="cause">
              Déclencheur*
            </label>
            <select class="fr-select" id="cause" name="cause" formControlName="cause">
              <option value="Inconnue">Je ne sais pas</option>
              <option value="Différend de circulation">Différend de circulation</option>
              <option value="Ébriété">Ébriété</option>
              <option value="Incident de contrôle">Incident de contrôle</option>
              <option value="Interposition / Assistance">Interposition / Assistance</option>
              <option value="Problème tarifaire">Problème tarifaire</option>
              <option value="Rappel du règlement">Rappel du règlement</option>
              <option value="Représailles">Représailles</option>
              <option value="Trouble du comportement">Trouble du comportement</option>
              <option value="Violence urbaine">Violence urbaine</option>
              <option value="Autre">Autre</option>
            </select>
            <p *ngIf="isSubmitted && createForm.controls?.cause?.errors?.required" class="fr-error-text">
              La cause est obligatoire.
            </p>
          </div>

          <h3 class="fr-text--lg fr-mb-4v">Personne(s) impliquée(s)</h3>
          <label class="fr-label" for="cause">
            Auteur(s)
          </label>
          <div class="fr-table fr-table--bordered fr-table--condensed fr-table--no-caption fr-table--full fr-table--fit fr-mb-6v fr-mt-1w">
            <table>
              <caption>Tabeau des auteurs</caption>
              <thead>
                <tr>
                  <th class="fr-th--full" scope="col">Qualité</th>
                  <th class="fr-th--full" scope="col">Fonction</th>
                  <th class="fr-th--full" scope="col">Sexe</th>
                  <th scope="col">
                    <button (click)="onAddPerpetratorButtonClick()" class="fr-btn fr-btn--sm fr-icon-add-line fr-btn--secondary" title="Ajouter un auteur">
                      Ajouter un auteur
                    </button>
                </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="!perpetrators.length">
                  <td colspan="4">Aucun auteur n'a été ajouté pour le moment.</td>
                </tr>
                <tr
                  *ngFor="let perpetrator of perpetrators">
                  <td>{{ perpetrator.quality }}</td>
                  <td>{{ perpetrator.function }}</td>
                  <td>{{ perpetrator.gender }}</td>
                  <td class="fit">
                    <button (click)="onRemovePerpetratorButtonClick(perpetrator.id)" class="fr-btn fr-btn--sm fr-icon-delete-line fr-btn--secondary"
                      title="Supprimer l'auteur">
                      Supprimer l'auteur
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <label class="fr-label" for="cause">
            Victime(s)
          </label>
          <div class="fr-table fr-table--bordered fr-table--condensed fr-table--no-caption fr-table--full fr-table--fit fr-mb-6v fr-mt-1w">
            <table>
              <caption>Tabeau des victimes</caption>
              <thead>
                <tr>
                  <th class="fr-th--full" scope="col">Qualité</th>
                  <th class="fr-th--full" scope="col">Fonction</th>
                  <th class="fr-th--full" scope="col">Sexe</th>
                  <th class="fr-th--full" scope="col">ITT</th>
                  <th scope="col">
                    <button (click)="onAddVictimButtonClick()" class="fr-btn fr-btn--sm fr-icon-add-line fr-btn--secondary"
                      title="Ajouter une victime">
                      Ajouter une victime
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="!victims.length">
                  <td colspan="5">Aucune victime n'a été ajouté pour le moment.</td>
                </tr>
                <tr *ngFor="let victim of victims">
                  <td>{{ victim.quality }}</td>
                  <td>{{ victim.function }}</td>
                  <td>{{ victim.gender }}</td>
                  <td>{{ victim.incapacity }}</td>
                  <td class="fit">
                    <button (click)="onRemoveVictimButtonClick(victim.id)"
                      class="fr-btn fr-btn--sm fr-icon-delete-line fr-btn--secondary" title="Supprimer la victime">
                      Supprimer la victime
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <label class="fr-label" for="cause">
            Protagoniste(s)
          </label>
          <div class="fr-table fr-table--bordered fr-table--condensed fr-table--no-caption fr-table--full fr-table--fit fr-mb-6v fr-mt-1w">
            <table>
              <caption>Tabeau des protagonistes</caption>
              <thead>
                <tr>
                  <th class="fr-th--full" scope="col">Type</th>
                  <th class="fr-th--full" scope="col">Fonction</th>
                  <th class="fr-th--full" scope="col">Sexe</th>
                  <th class="fr-th--full fit" scope="col">
                    <button (click)="onAddProtagonistButtonClick()" class="fr-btn fr-btn--sm fr-icon-add-line fr-btn--secondary"
                      title="Ajouter un protagoniste">
                      Ajouter un protagoniste
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="!protagonists.length">
                  <td colspan="4">Aucun protagoniste n'a été ajouté pour le moment.</td>
                </tr>
                <tr *ngFor="let protagonist of protagonists">
                  <td>{{ protagonist.type }}</td>
                  <td>{{ protagonist.function }}</td>
                  <td>{{ protagonist.gender }}</td>
                  <td class="fit">
                    <button (click)="onRemoveProtagonistButtonClick(protagonist.id)"
                      class="fr-btn fr-btn--sm fr-icon-delete-line fr-btn--secondary" title="Supprimer le protagoniste">
                      Supprimer le protagoniste
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <h3 class="fr-text--lg fr-mb-4v">Bien(s) impacté(s)</h3>
          <div
            class="fr-table fr-table--bordered fr-table--condensed fr-table--no-caption fr-table--full fr-table--fit fr-mb-6v fr-mt-1w">
            <table>
              <caption>Tabeau des biens impactés</caption>
              <thead>
                <tr>
                  <th class="fr-th--full" scope="col">Qualité</th>
                  <th class="fr-th--full" scope="col">Type</th>
                  <th class="fr-th--full fit" scope="col">
                    <button (click)="onAddGoodButtonClick()" class="fr-btn fr-btn--sm fr-icon-add-line fr-btn--secondary"
                      title="Ajouter un bien">
                      Supprimer un bien
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="!goods.length">
                  <td colspan="3">Aucun bien n'a été ajouté pour le moment.</td>
                </tr>
                <tr *ngFor="let good of goods">
                  <td>{{ good.quality }}</td>
                  <td>{{ good.type }}</td>
                  <td class="fit">
                    <button (click)="onRemoveGoodButtonClick(good.id)"
                      class="fr-btn fr-btn--sm fr-icon-delete-line fr-btn--secondary" title="Supprimer le bien">
                      Supprimer le bien
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
  
        </form>
      </div>
      <div class="fr-modal__footer">
        <ul
          class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
          <li>
            <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Annuler</button>
          </li>
          <li>
            <button (click)="onSubmit()" type="submit" class="fr-btn">Créer</button>
          </li>
        </ul>
      </div>
  
    </div>
  </div>
</ion-content>

