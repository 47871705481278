import { createReducer, on } from '@ngrx/store';
import * as NetworkAction from './network.actions';
import { Network } from './network.model';

export const featureKey = 'network';

export interface State {
  networks: Network[];
  networkCount: number;
  errorMessage: string;
}

export const initialState: State = {
  networks: [],
  networkCount: 0,
  errorMessage: null
};

export const reducer = createReducer(
  initialState,

  on(NetworkAction.getNetworksSuccess, (state, action) => ({
    ...state,
    networks: action.networks,
    networkCount: action.networkCount,
  })),

);
