<div class="inner-content">
  <div class="fr-modal__body">
    <div class="fr-modal__header"></div>
    <div class="fr-modal__content">
      <h1 id="fr-modal-2-title" class="fr-modal__title">
        Supression
      </h1>
      <p>Êtes-vous sûr(e) de vouloir tout supprimer ?</p>
    </div>
    <div class="fr-modal__footer">
      <ul
        class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
        <li>
          <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Non</button>
        </li>
        <li>
          <button (click)="onSubmit()" type="submit" class="fr-btn">Oui</button>
        </li>
      </ul>
    </div>
  </div>
</div>