<div class="inner-content">
  <div class="fr-modal__body">

    <div class="fr-modal__header"></div>
    <div class="fr-modal__content">
      <h1 id="fr-modal-2-title" class="fr-modal__title">
        Modification d'un réseau
      </h1>
      <form *ngIf="updateForm" [formGroup]="updateForm" novalidate>
        <div *ngIf="currentUser.isSupervisor" class="fr-input-group">
          <label class="fr-label" for="name">
            Nom du réseau*
          </label>
          <input class="fr-input" type="text" id="name" name="name" formControlName="name">
          <p *ngIf="isSubmitted && updateForm.controls?.name?.errors?.required" class="fr-error-text">
            Le nom du réseau de transport est obligatoire.
          </p>
          <p *ngIf="isSubmitted && updateForm.controls?.name?.errors?.minlength" class="fr-error-text">
            Le nom du réseau de transport doit avoir au moins 2 caractères.
          </p>
        </div>
        <div *ngIf="currentUser.isSupervisor" class="fr-input-group">
          <label class="fr-label" for="group">
            Groupe / Régie*
          </label>
          <input class="fr-input" type="text" id="group" name="group" formControlName="group">
          <p *ngIf="isSubmitted && updateForm.controls?.group?.errors?.required" class="fr-error-text">
            Le groupe / régie du réseau de transport est obligatoire.
          </p>
          <p *ngIf="isSubmitted && updateForm.controls?.group?.errors?.minlength" class="fr-error-text">
            Le groupe / régie du réseau de transport doit avoir au moins 2 caractères.
          </p>
        </div>
        <div *ngIf="currentUser.isSupervisor" class="fr-input-group">
          <label class="fr-label" for="authority">
            Autorité organisatrice*
          </label>
          <input class="fr-input" type="text" id="authority" name="authority" formControlName="authority">
          <p *ngIf="isSubmitted && updateForm.controls?.authority?.errors?.required" class="fr-error-text">
            L'autorité organisatrice du réseau de transport est obligatoire.
          </p>
          <p *ngIf="isSubmitted && updateForm.controls?.authority?.errors?.minlength" class="fr-error-text">
            L'autorité organisatrice du réseau de transport doit avoir au moins 2 caractères.
          </p>
        </div>
        <div *ngIf="currentUser.isManager" class="fr-select-group fr-select-group--relative">
          <label class="fr-label" for="transports">
            Mode(s) de transport
          </label>
          <ng-select id="transports" name="transports" formControlName="transports" [items]="transports" multiple="true"
            appendTo="body" loadingText="Chargement..." [clearable]="false" notFoundText="Aucun mode de transport trouvé."
            typeToSearchText="Veuillez entrer au moins 2 caractères" [hideSelected]="true">
          </ng-select>
          <div class="ng-select ng-select--fake">
            <span class="ng-clear-wrapper ng-clear-wrapper-custom" title="Tout retirer" (click)="onClearTransports()">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span>
          </div>
        </div>
        <div *ngIf="currentUser.isManager" class="fr-select-group fr-select-group--relative">
          <label class="fr-label" for="towns">
            Commune(s)
          </label>
          <ng-select id="towns" name="towns" formControlName="towns" multiple="true" appendTo="body" [minTermLength]="2"
            [trackByFn]="trackByFn" bindLabel="name" loadingText="Chargement..." [clearable]="false"
            notFoundText="Aucune commune trouvée." [compareWith]="compareTown"
            typeToSearchText="Veuillez entrer au moins 2 caractères" [typeahead]="townInput$" [loading]="townLoading">
            <ng-option *ngFor="let town of towns$ | async" [value]="town.value">{{town.name}}</ng-option>
          </ng-select>
          <div class="ng-select ng-select--fake">
            <span class="ng-clear-wrapper ng-clear-wrapper-custom" title="Tout retirer" (click)="onClearTowns()">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span>
          </div>
        </div>
      </form>
    </div>
    <div class="fr-modal__footer">
      <ul
        class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
        <li>
          <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Annuler</button>
        </li>
        <li>
          <button (click)="onSubmit()" type="submit" class="fr-btn">Mettre à jour</button>
        </li>
      </ul>
    </div>

  </div>
</div>