import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { FactService } from 'src/app/core/services/fact.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AppState } from 'src/app/store/app.state';
import { FactGood } from 'src/app/store/fact/fact-good.model';
import { FactPerson } from 'src/app/store/fact/fact-person.model';
import { createFact } from 'src/app/store/fact/fact.actions';
import { Network } from 'src/app/store/network/network.model';
import { User } from 'src/app/store/user/user.model';
import { AddGoodModalComponent } from '../add-good-modal/add-good-modal.component';
import { AddPerpetratorModalComponent } from '../add-perpetrator-modal/add-perpetrator-modal.component';
import { AddProtagonistModalComponent } from '../add-protagonist-modal/add-protagonist-modal.component';
import { AddVictimModalComponent } from '../add-victim-modal/add-victim-modal.component';

@Component({
  selector: 'app-fact-create',
  templateUrl: './fact-create.component.html',
})
export class FactCreateComponent implements OnInit {
  currentUser: User;
  networks: Network[];
  towns: any[];
  transports: string[];
  persons: FactPerson[];
  perpetrators: FactPerson[];
  victims: FactPerson[];
  protagonists: FactPerson[];
  goods: FactGood[];
  nomenclatures: any[];
  createForm: UntypedFormGroup;
  isSubmitted = false;

  constructor(
    private store: Store<AppState>,
    private factService: FactService,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private modalController: ModalController) {
      this.persons = [];
      this.perpetrators = [];
      this.victims = [];
      this.protagonists = [];
      this.goods = [];
      this.nomenclatures = this.factService.getNomenclatures();
    }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.networks = this.currentUser.networks;
    const networkId = this.networks[0].id;
    this.createForm = this.formBuilder.group({
      date: [formatDate(new Date(), 'yyyy-MM-dd', 'fr'), [
        Validators.required,
      ]],
      time: [formatDate(new Date(), 'HH:mm', 'fr'), [
        Validators.required,
      ]],
      networkId: [networkId, [
        Validators.required,
      ]],
      town: ['', [
        Validators.required,
      ]],
      transport: ['', [
        Validators.required,
      ]],
      stop: [''],
      line: [''],
      priorityZone: [null],
      nomenclature: ['A1', [
        Validators.required,
      ]],
      serviceImpact: [null],
      offerModification: [null],
      departmentIntervention: [null],
      policeIntervention: [null],
      emergencyIntervention: [null],
      complaint: [null],
      weapon: [null],
      cause: ['Inconnue', [
        Validators.required,
      ]],
      persons: [this.persons, []],
      goods: [this.goods, []],
    });

    this.onChangeNetwork(networkId);
  }

  onChangeNetwork(networkId: number) {
    this.refreshTowns(networkId);
    this.refreshTransports(networkId);
  }

  refreshTowns(networkId) {
    this.towns = this.currentUser.networks
      .filter((_network) => _network.id == networkId)
      .map((_network) => _network.towns ?? [])
      .reduce((_town) => _town)
      .sort((_townA, _townB) => _townA.localeCompare(_townB))
      .map((_town) => {
        return {
          name: _town.split('[')[0].trim(),
          value: _town
        }
      });

    this.towns?.length
      ? this.createForm.controls.town.setValue(this.towns[0].value)
      : this.createForm.controls.town.reset();
  }

  refreshTransports(networkId) {
    this.transports = this.currentUser.networks
      .filter((_network) => _network.id == networkId)
      .map((_network) => _network.transports ?? [])
      .reduce((_transport) => _transport)
      .sort((_transportA, _transportB) => _transportA.localeCompare(_transportB));

    this.transports?.length
      ? this.createForm.controls.transport.setValue(this.transports[0])
      : this.createForm.controls.transport.reset();
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.createForm.valid) {
      return false;
    }

    this.store.dispatch(createFact({
      ...this.createForm.value,
    }));
    
    this.modalController.dismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  async onAddPerpetratorButtonClick() {
    const modal = await this.modalController.create({
      component: AddPerpetratorModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const perpetrator = data['data'] as FactPerson;
      if (perpetrator) {
        this.perpetrators.push(perpetrator);
        this.persons.push(perpetrator);
        this.createForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  onRemovePerpetratorButtonClick(perpetratorId) {
    if (!perpetratorId) {
      return;
    }

    this.perpetrators = this.perpetrators.filter((_perpetrator) => _perpetrator.id != perpetratorId);
    this.persons = this.persons.filter((_person) => _person.id != perpetratorId);
    this.createForm.controls.persons.setValue(this.persons);
  }

  async onAddVictimButtonClick() {
    const modal = await this.modalController.create({
      component: AddVictimModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const victim = data['data'] as FactPerson;
      if (victim) {
        this.victims.push(victim);
        this.persons.push(victim);
        this.createForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  onRemoveVictimButtonClick(victimId) {
    if (!victimId) {
      return;
    }

    this.victims = this.victims.filter((_victim) => _victim.id != victimId);
    this.persons = this.persons.filter((_person) => _person.id != victimId);
    this.createForm.controls.persons.setValue(this.persons);
  }

  async onAddProtagonistButtonClick() {
    const modal = await this.modalController.create({
      component: AddProtagonistModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const protagonist = data['data'] as FactPerson;
      if (protagonist) {
        this.protagonists.push(protagonist);
        this.persons.push(protagonist);
        this.createForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  onRemoveProtagonistButtonClick(protagonistId) {
    if (!protagonistId) {
      return;
    }

    this.protagonists = this.protagonists.filter((_protagonist) => _protagonist.id != protagonistId);
    this.persons = this.persons.filter((_person) => _person.id != protagonistId);
    this.createForm.controls.persons.setValue(this.persons);
  }

  async onAddGoodButtonClick() {
    const modal = await this.modalController.create({
      component: AddGoodModalComponent,
      cssClass: 'auto-height',
    });

    modal.onDidDismiss().then((data) => {
      const good = data['data'] as FactGood;
      if (good) {
        this.goods.push(good);
        this.createForm.controls.goods.setValue(this.goods);
      }
    });

    await modal.present();
  }

  onRemoveGoodButtonClick(goodId) {
    if (!goodId) {
      return;
    }

    this.goods = this.goods.filter((_good) => _good.id != goodId);
    this.createForm.controls.goods.setValue(this.goods);
  }

  async onAdd() {
    const modal = await this.modalController.create({
      component: AddPerpetratorModalComponent,
      cssClass: 'auto-height',
    });

    await modal.present();
  }

}
