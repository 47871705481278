import { Injectable } from '@angular/core';
import { AppState } from '@capacitor/app';
import { ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Notification } from 'src/app/store/notification/notification.model';
import { clearNotification } from 'src/app/store/notification/notification.actions';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private toast: HTMLIonToastElement;
  private toasting = false;

  constructor(
    private toastController: ToastController,
    private store: Store<AppState>) { }

  async show(notification: Notification) {
    if (notification && !this.toasting) {
      const isSuccess = notification.type === 'SUCCESS';
      this.toasting = true;
      this.toast = await this.toastController.create({
        message: notification?.message
          ? notification.message
          : 'Une erreur innattendue s\'est produite.',
        icon: isSuccess ? 'information-circle' : 'close-circle',
        position: 'top',
        duration: 5000,
        cssClass: isSuccess ? 'fr-toast--success' : 'fr-toast--error'
      });
      this.toast.present();
      this.store.dispatch(clearNotification());
      this.toast.onDidDismiss().then(() => {
        this.toasting = false;
      });
    }
  }
}
