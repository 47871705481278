import { Network } from '../network/network.model';

export class User {
  id: number;
  uuid: string;
  username: string;
  email: string;
  firstname: string;
  lastname: string;
  name: string;
  password: string;
  role: string;
  networks: Network[];
  networkIds: number[];
  validated: boolean;
  accessToken: string;
  refreshToken: string;
  createdAt: Date;
  updatedAt: Date;
  isAuthentificated: boolean;

  constructor(obj: any) {
    if (obj) {
      Object.assign(this, obj);
    }
  }

  public get isAdmin(): boolean {
    return this.role === 'Admininistrateur';
  }

  public get isSupervisor(): boolean {
    return this.role === 'Agent ONDT';
  }

  public get isManager(): boolean {
    return this.role === 'Responsable';
  }

  public get isAgent(): boolean {
    return this.role === 'Agent';
  }
}
