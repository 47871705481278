<div class="inner-content">
  <div class="fr-modal__body">

    <div class="fr-modal__header"></div>
    <div class="fr-modal__content">
      <h1 id="fr-modal-2-title" class="fr-modal__title">
        Modification d'un utilisateur
      </h1>
      <form *ngIf="updateForm" [formGroup]="updateForm" novalidate>
        <div class="fr-input-group">
          <label class="fr-label" for="firstname">
            Prénom*
          </label>
          <input class="fr-input" type="text" id="firstname" name="firstname" formControlName="firstname">
          <p *ngIf="isSubmitted && updateForm.controls?.firstname?.errors?.required" class="fr-error-text">
            Le prénom de l'utilisateur est obligatoire.
          </p>
          <p *ngIf="isSubmitted && updateForm.controls?.firstname?.errors?.minlength" class="fr-error-text">
            Le prénom de l'utilisateur doit avoir au moins 2 caractères.
          </p>
        </div>
        <div class="fr-input-group">
          <label class="fr-label" for="lastname">
            Nom*
          </label>
          <input class="fr-input" type="text" id="lastname" name="lastname" formControlName="lastname">
          <p *ngIf="isSubmitted && updateForm.controls?.lastname?.errors?.required" class="fr-error-text">
            Le nom de l'utilisateur est obligatoire.
          </p>
          <p *ngIf="isSubmitted && updateForm.controls?.lastname?.errors?.minlength" class="fr-error-text">
            Le nom de l'utilisateur doit avoir au moins 2 caractères.
          </p>
        </div>
        <div *ngIf="!isAdmin" class="fr-select-group">
          <label class="fr-label" for="networkIds">
            Réseau(x)*
          </label>
          <ng-select id="networkIds" name="networkIds" formControlName="networkIds" [items]="networks$ | async"
            multiple="true" bindLabel="name" bindValue="id" dropdownPosition="top" loadingText="Chargement..."
            clearAllText="Tout retirer" notFoundText="Aucun réseau trouvé."
            typeToSearchText="Veuillez entrer au moins 2 caractères" [hideSelected]="true">
          </ng-select>
          <p *ngIf="isSubmitted && updateForm.controls?.networkIds?.errors?.required" class="fr-error-text">
            L'utilisateur doit être positionné sur au moins un réseau.
          </p>
        </div>
      </form>
    </div>
    <div class="fr-modal__footer">
      <ul
        class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
        <li>
          <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Annuler</button>
        </li>
        <li>
          <button (click)="onSubmit()" type="submit" class="fr-btn">Mettre à jour</button>
        </li>
      </ul>
    </div>

  </div>
</div>