import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { FactsPageRoutingModule } from './facts-routing.module';
import { FactsPage } from './facts.page';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FactUpdateComponent } from './components/fact-update/fact-update.component';
import { AddPerpetratorModalComponent } from './components/add-perpetrator-modal/add-perpetrator-modal.component';
import { AddProtagonistModalComponent } from './components/add-protagonist-modal/add-protagonist-modal.component';
import { AddVictimModalComponent } from './components/add-victim-modal/add-victim-modal.component';
import { AddGoodModalComponent } from './components/add-good-modal/add-good-modal.component';
import { FactDeleteComponent } from './components/fact-delete/fact-delete.component';
import { FactCreateComponent } from './components/fact-create/fact-create.component';
import { ModifyVictimModalComponent } from './components/modify-victim-modal/modify-victim-modal.component';
import { ModifyPerpetratorModalComponent } from './components/modify-perpetrator-modal/modify-perpetrator-modal.component';
import { ModifyProtagonistModalComponent } from './components/modify-protagonist-modal/modify-protagonist-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModifyGoodModalComponent } from './components/modify-good-modal/modify-good-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    FactsPageRoutingModule
  ],
  declarations: [
    FactCreateComponent,
    FactUpdateComponent,
    FactDeleteComponent,
    AddPerpetratorModalComponent,
    ModifyPerpetratorModalComponent,
    AddProtagonistModalComponent,
    ModifyProtagonistModalComponent,
    AddVictimModalComponent,
    ModifyVictimModalComponent,
    AddGoodModalComponent,
    ModifyGoodModalComponent,
    FactsPage
  ]
})
export class FactsPageModule {}
