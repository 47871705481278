import { createAction, props } from '@ngrx/store';
import { NetworkQuery } from 'src/app/core/models/network-query.model';
import { Network } from './network.model';

// Get
export const getNetworks = createAction('[Network] Get networks', props<NetworkQuery>());
export const getNetworksSuccess = createAction('[Network] Get networks success', props<{
    networks: Network[];
    networkCount: number;
}>());

export const getAllNetworks = createAction('[Network] Get all networks');

// Create
export const createNetwork = createAction('[Network] Create network', props<Network>());
export const updateNetwork = createAction('[Network] Update network', props<Network>());
export const deleteNetwork = createAction('[Network] Delete network', props<Network>());
