<div class="inner-content">
  <div class="fr-modal__body">
    
      <div class="fr-modal__header"></div>
      <div class="fr-modal__content">
        <h1 id="fr-modal-2-title" class="fr-modal__title">
          Ajout d'un réseau
        </h1>
        <form [formGroup]="createForm" novalidate>
          <div class="fr-input-group">
            <label class="fr-label" for="name">
              Nom du réseau*
            </label>
            <input class="fr-input" type="text" id="name" name="name" formControlName="name">
            <p *ngIf="isSubmitted && createForm.controls?.name?.errors?.required"
              class="fr-error-text">
              Le nom du réseau est obligatoire.
            </p>
            <p *ngIf="isSubmitted && createForm.controls?.name?.errors?.minlength"
              class="fr-error-text">
              Le nom du réseau doit avoir au moins 2 caractères.
            </p>
          </div>
          <div class="fr-input-group">
            <label class="fr-label" for="group">
              Groupe / Régie*
            </label>
            <input class="fr-input" type="text" id="group" name="group" formControlName="group">
            <p *ngIf="isSubmitted && createForm.controls?.group?.errors?.required"
              class="fr-error-text">
              Le groupe / régie du réseau est obligatoire.
            </p>
            <p *ngIf="isSubmitted && createForm.controls?.group?.errors?.minlength"
              class="fr-error-text">
              Le groupe / régie du réseau doit avoir au moins 2 caractères.
            </p>
          </div>
          <div class="fr-input-group">
            <label class="fr-label" for="authority">
              Autorité organisatrice*
            </label>
            <input class="fr-input" type="text" id="authority" name="authority" formControlName="authority">
            <p *ngIf="isSubmitted && createForm.controls?.authority?.errors?.required"
              class="fr-error-text">
              L'autorité organisatrice du réseau est obligatoire.
            </p>
            <p *ngIf="isSubmitted && createForm.controls?.authority?.errors?.minlength"
              class="fr-error-text">
              L'autorité organisatrice du réseau doit avoir au moins 2 caractères.
            </p>
          </div>
        </form>
      </div>
      <div class="fr-modal__footer">
        <ul
          class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
          <li>
            <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Annuler</button>
          </li>
          <li>
            <button (click)="onSubmit()" type="submit" class="fr-btn">Ajouter</button>
          </li>
        </ul>
      </div>
  </div>
</div>