import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/core/services/storage.service';
import { User } from 'src/app/store/user/user.model';
import { FactGood } from 'src/app/store/fact/fact-good.model';

@Component({
  selector: 'app-add-good-modal',
  templateUrl: './add-good-modal.component.html',
})
export class AddGoodModalComponent implements OnInit {

  createForm: UntypedFormGroup;
  isSubmitted = false;
  currentUser: User;
  isEnterprise = false;

  constructor(
    private modalController: ModalController,
    private storageService: StorageService,
    private formBuilder: UntypedFormBuilder) {
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
   
    this.createForm = this.formBuilder.group({
      quality: ['Inconnu', [
        Validators.required,
      ]],
      type: [null, []],
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.createForm.valid) {
      return false;
    }

    const good = new FactGood({
      id: Math.random().toString(36).substring(2, 10), // NOSONAR
      quality: this.createForm.controls.quality.value,
      type: this.createForm.controls.type.value,
    })

    this.modalController.dismiss(good);
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  onChangeQuality(quality) {
    if (quality === "Bien de l'entreprise") {
      this.isEnterprise = true;
      this.createForm.controls.type.setValue('Installation fixe');
    } else {
      this.isEnterprise = false;
      this.createForm.controls.type.setValue(null);
    }
  }
}
