import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import * as NetworkAction from './network.actions';
import * as NotificationAction from '../notification/notification.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { NetworkService } from 'src/app/core/services/network.service';
import { Network } from './network.model';
import { Response } from 'src/app/core/models/response.model';
import { NetworkQuery } from 'src/app/core/models/network-query.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class NetworkEffects {

  getNetworks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NetworkAction.getNetworks),
      switchMap((query: NetworkQuery) =>
        this.networkService.get(query).pipe(
          map((response: Response<Network[]>) => NetworkAction.getNetworksSuccess({
            networks: response.data,
            networkCount: response.count
          })),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  getAllNetworks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NetworkAction.getAllNetworks),
      switchMap(() =>
        this.networkService.getAll().pipe(
          map((response: Response<Network[]>) => NetworkAction.getNetworksSuccess({ // NOSONAR
            networks: response.data,
            networkCount: response.count
          })),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  createNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NetworkAction.createNetwork),
      switchMap((network: Network) =>
        this.networkService.create(network).pipe(
          map(() => NotificationAction.showNotificationSuccess({ message: `Le réseau '${network.name}' a bien été ajouté.`})),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  updateNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NetworkAction.updateNetwork),
      switchMap((network: Network) =>
        this.networkService.update(network).pipe(
          map(() => NotificationAction.showNotificationSuccess({ message: `Le réseau '${network.name}' a bien été mis à jour.`})),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  deleteNetwork$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NetworkAction.deleteNetwork),
      switchMap((network: Network) =>
        this.networkService.delete(network).pipe(
          map(() => NotificationAction.showNotificationSuccess({ message: `Le réseau '${network.name}' a bien été supprimé.`})),
          catchError((httpErrorResponse: HttpErrorResponse ) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  constructor(
    private actions$: Actions,
    private networkService: NetworkService
  ) {}
}
