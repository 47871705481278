import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UsersPageRoutingModule } from './users-routing.module';
import { UsersPage } from './users.page';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { CreateModalComponent } from './components/create-modal/create-modal.component';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    UsersPageRoutingModule
  ],
  declarations: [
    UsersPage,
    CreateModalComponent,
    UpdateModalComponent,
    DeleteModalComponent,
  ]
})
export class UsersPageModule {}
