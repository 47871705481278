import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NetworksPageRoutingModule } from './networks-routing.module';
import { NetworksPage } from './networks.page';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { CreateModalComponent } from './components/create-modal/create-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NetworksPageRoutingModule
  ],
  declarations: [
    NetworksPage,
    CreateModalComponent,
    UpdateModalComponent,
    DeleteModalComponent,
  ]
})
export class NetworksPageModule {}
