import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/app.state';
import { getFacts } from 'src/app/store/fact/fact.actions';
import { Fact } from 'src/app/store/fact/fact.model';
import { Notification } from 'src/app/store/notification/notification.model';
import { selectFactCount, selectFacts } from 'src/app/store/fact/fact.selectors';
import { selectNotification } from 'src/app/store/notification/notification.selectors';
import { NotificationService } from 'src/app/core/services/notification.service';
import { User } from 'src/app/store/user/user.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { Network } from 'src/app/store/network/network.model';
import { saveAs, encodeBase64  } from '@progress/kendo-file-saver';
import { FactService } from 'src/app/core/services/fact.service';
import { Response } from 'src/app/core/models/response.model';
import { formatDate } from '@angular/common';
import { CSVHelper } from './helpers/facts.helper';
import { NetworkHelper } from 'src/app/core/helpers/network.helper';
import { FactUpdateComponent } from './components/fact-update/fact-update.component';
import { FactDeleteComponent } from './components/fact-delete/fact-delete.component';
import { FactCreateComponent } from './components/fact-create/fact-create.component';

@Component({
  selector: 'app-facts',
  templateUrl: './facts.page.html',
})
export class FactsPage implements OnInit {

  facts$: Observable<Fact[]> = this.store.select(selectFacts);
  factCount$: Observable<number> = this.store.select(selectFactCount);
  notification$: Observable<any> = this.store.select(selectNotification);
  currentUser: User;
  hasFacts = false;
  isAdmin: boolean;
  isSupervisor: boolean;
  isManager: boolean;
  page = 1;
  limit = 10;
  userNameFilter = '';
  networks = [];
  networkIdFilter = '';
  towns = [];
  idFilter = '';
  nomenclatureFilter = '';
  townFilter = '';
  startDateFilter = '';
  endDateFilter = '';
  nomenclatureIds: string[];
  nomenclatures: any[];
  parseTown = NetworkHelper.parseTown;

  constructor(
    private store: Store<AppState>,
    private factService: FactService,
    private modalController: ModalController,
    private notificationService: NotificationService,
    private storageService: StorageService) {
      const nomenclatures = this.factService.getNomenclatures();
      this.nomenclatures  = Object.assign({}, ...nomenclatures
        .map((_nomenclature) => ({[_nomenclature.type]: _nomenclature.label})));
      this.nomenclatureIds = Object.keys(this.nomenclatures);
      this.factCount$.subscribe((count) => this.hasFacts = count > 0);
      this.notification$.subscribe(async (notification: Notification) => {
        this.notificationService.show(notification);
        if (notification && notification.type === 'SUCCESS') {
          this.refreshData();
        }
      });
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.isAdmin = this.currentUser && this.currentUser.isAdmin;
    this.isSupervisor = this.currentUser && this.currentUser.isSupervisor;
    this.isManager = this.currentUser && this.currentUser.isManager;
    const userNetworks = this.currentUser.networks;
    userNetworks.sort((a: Network, b: Network) => a.name.localeCompare(b.name));
    this.networks = userNetworks;
    this.refreshData();
    this.facts$.subscribe((facts) => {
      if (this.townFilter == '') {
        const managerTowns = this.currentUser.networks
          .filter((_network) => _network.towns?.length)
          .map((_network) => _network.towns)
          .flat();
        const factTowns = facts.map(_fact => _fact.town);
        const towns = [...new Set([...managerTowns ,...factTowns])];
        towns.sort();
        this.towns = towns;
      }
    });
  }

  onApply() {
    this.page = 1;
    this.refreshData();
  }

  onReset() {
    this.page = 1;
    this.idFilter = '';
    this.nomenclatureFilter = '';
    this.userNameFilter = '';
    this.networkIdFilter = '';
    this.townFilter = '';
    this.startDateFilter = '';
    this.endDateFilter = '';
    this.refreshData();
  }

  onPageChanged(event) {
    this.page = event;
    this.refreshData();
  }

  onPageSizeChanged(event) {
    this.page = 1;
    this.limit = event.target.value;
    this.refreshData();
  }

  async onCreate() {
     const modal = await this.modalController.create({
      component: FactCreateComponent,
      cssClass: 'large'
    });

    await modal.present();
  }

  async onExport() {
    this.factService.get({
      page: 1,
      limit: 10000,
      id: this.idFilter,
      nomenclature: this.nomenclatureFilter,
      networkId: this.networkIdFilter,
      userName: this.userNameFilter,
      town: this.townFilter,
      startDate: this.startDateFilter,
      endDate: this.endDateFilter
    })
    .subscribe(async (response: Response<Fact[]>) => {
      const facts = response.data;
      const csv = CSVHelper.toCSV(facts);
      this.downloadFiles(csv);
    });
  }

  async onUpdateFact(fact: Fact) {
    const modal = await this.modalController.create({
      component: FactUpdateComponent,
      cssClass: 'large',
      componentProps : { fact }
    });

    await modal.present();
  }

  async onDeleteFact(fact: Fact) {
    const modal = await this.modalController.create({
      component: FactDeleteComponent,
      cssClass: 'auto-height',
      componentProps : { fact }
    });

    await modal.present();
  }

  async downloadFiles(csv) {
    const date = formatDate(new Date(), 'ddMMyy', 'fr');
    saveAs("data:text/plain;base64," + encodeBase64(csv.factContent), `faits_${date}.csv`);
    saveAs("data:text/plain;base64," + encodeBase64(csv.personContent), `personnes_${date}.csv`);
    saveAs("data:text/plain;base64," + encodeBase64(csv.goodContent), `biens_${date}.csv`);
  }

  refreshData() {
    this.store.dispatch(getFacts({
      page: this.page,
      limit: this.limit,
      id: this.idFilter,
      nomenclature: this.nomenclatureFilter,
      networkId: this.networkIdFilter,
      userName: this.userNameFilter,
      town: this.townFilter,
      startDate: this.startDateFilter,
      endDate: this.endDateFilter
    }));
  }
}
