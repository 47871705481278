import { User } from './user.model';
import { createReducer, on } from '@ngrx/store';
import * as UserAction from './user.actions';

export const featureKey = 'user';

export interface State {
  currentUser: User;
  users: User[];
  userCount: number;
  errorMessage: string;
}

export const initialState: State = {
  currentUser: null,
  users: [],
  userCount: 0,
  errorMessage: null
};

export const reducer = createReducer(
  initialState,

  on(UserAction.getUsersSuccess, (state, action) => ({
    ...state,
    users: action.users,
    userCount: action.userCount,
  })),

);
