import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { createNetwork } from 'src/app/store/network/network.actions';

@Component({
  selector: 'app-create-modal',
  templateUrl: './create-modal.component.html',
})
export class CreateModalComponent implements OnInit {

  createForm: UntypedFormGroup;
  isSubmitted = false;

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder) {
  }

  ngOnInit() {
    this.createForm = this.formBuilder.group({
      name: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      group: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
      authority: ['', [
        Validators.required,
        Validators.minLength(3)
      ]],
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.createForm.valid) {
      this.store.dispatch(createNetwork(this.createForm.value));
      this.modalController.dismiss();
    } else {
      return false;
    }
  }

  onDismiss() {
    this.modalController.dismiss();
  }

}
