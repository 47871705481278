import { Component, OnInit  } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { AppState } from 'src/app/store/app.state';
import { selectNotification } from 'src/app/store/notification/notification.selectors';
import { Notification } from 'src/app/store/notification/notification.model';
import { Network } from '@capacitor/network';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {
  notification$: Observable<any> = this.store.select(selectNotification);
  isConnected: boolean = false;
  connectionType: string = '';

  constructor(
    private store: Store<AppState>,
    private platform: Platform,
    private notificationService: NotificationService) {
    this.notification$.subscribe(async (notification: Notification) => {
      this.notificationService.show(notification);
    });
  }

  async ngOnInit() {
      await this.platform.ready();
      this.checkNetworkStatus();

      // Écouter les changements de statut de réseau
      Network.addListener('networkStatusChange', (status) => {
        this.isConnected = status.connected;
        this.connectionType = status.connectionType;
        console.log('Network status changed');
        console.log('Connection type:', this.connectionType);
      });
    }

    async checkNetworkStatus() {
      const status = await Network.getStatus();
      this.isConnected = status.connected;
      this.connectionType = status.connectionType;

      console.log('Connection type:', this.connectionType);
    }
}
