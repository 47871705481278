import { createReducer, on } from '@ngrx/store';
import * as NotificationAction from './notification.actions';
import { Notification } from './notification.model';

export const featureKey = 'notification';

export interface State {
  notification: Notification;
}

export const initialState: State = {
  notification: null,
};

export const reducer = createReducer(
  initialState,

  on(NotificationAction.showNotificationError, (state, action) => ({
      ...state,
      notification: {
        type: 'ERROR',
        date: new Date(),
        message: action.message,
      },
    })),

  on(NotificationAction.showNotificationSuccess, (state, action) => ({
    ...state,
    notification: {
        type: 'SUCCESS',
        date: new Date(),
        message: action.message,
      },
  })),

  on(NotificationAction.clearNotification, (state, _action) => ({
    ...state,
    notification: null,
  })),

);
