import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { NotificationService } from 'src/app/core/services/notification.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AppState } from 'src/app/store/app.state';
import { getUsers } from 'src/app/store/user/user.actions';
import { Notification } from 'src/app/store/notification/notification.model';
import { User } from 'src/app/store/user/user.model';
import { selectUsers, selectUserCount } from 'src/app/store/user/user.selectors';
import { selectNotification } from 'src/app/store/notification/notification.selectors';
import { CreateModalComponent } from './components/create-modal/create-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.page.html',
})
export class UsersPage implements OnInit {

  users$: Observable<User[]> = this.store.select(selectUsers);
  userCount$: Observable<number> = this.store.select(selectUserCount);
  notification$: Observable<any> = this.store.select(selectNotification);
  currentUser: User;
  hasUsers = false;
  isAdmin: boolean;
  isSupervisor: boolean;
  isManager: boolean;
  page = 1;
  limit = 10;
  name = '';

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    private notificationService: NotificationService,
    private storageService: StorageService) {
      this.userCount$.subscribe((count) => this.hasUsers = count > 0);
      this.notification$.subscribe(async (notification: Notification) => {
        this.notificationService.show(notification);
        if (notification && notification.type === 'SUCCESS') {
          this.refreshData();
        }
      });
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.isAdmin = this.currentUser && this.currentUser.isAdmin;
    this.isSupervisor = this.currentUser && this.currentUser.isSupervisor;
    this.isManager = this.currentUser && this.currentUser.isManager;

    this.refreshData();
  }

  onApply() {
    this.page = 1;
    this.refreshData();
  }

  onReset() {
    this.page = 1;
    this.name = '';
    this.refreshData();
  }

  onPageChanged(page) {
    this.page = page;
    this.refreshData();
  }

  onPageSizeChanged(event) {
    this.page = 1;
    this.limit = event.target.value;
    this.refreshData();
  }

  async onCreateNetwork() {
    const modal = await this.modalController.create({
      component: CreateModalComponent,
      cssClass: 'auto-height',
    });
    await modal.present();
  }

  async onDeleteNetwork(user: User) {
    const modal = await this.modalController.create({
      component: DeleteModalComponent,
      cssClass: 'auto-height',
      componentProps : { user }
    });
    await modal.present();
  }

  async onUpdateNetwork(user: User) {
    const modal = await this.modalController.create({
      component: UpdateModalComponent,
      cssClass: 'auto-height',
      componentProps : { user }
    });
    await modal.present();
  }

  refreshData() {
    this.store.dispatch(getUsers({
      page: this.page,
      limit: 10,
      name: this.name
    }));
  }
}
