import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import * as UserAction from './user.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Response } from 'src/app/core/models/response.model';
import { User } from './user.model';
import { HttpErrorResponse } from '@angular/common/http';
import * as NotificationAction from '../notification/notification.actions';
import { UserQuery } from 'src/app/core/models/user-query.model';

@Injectable()
export class UserEffects {

  getUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.getUsers),
      switchMap((query: UserQuery) =>
        this.userService.get(query).pipe(
          map((response: Response<User[]>) => UserAction.getUsersSuccess({
            users: response.data,
            userCount: response.count
          })),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.createUser),
      switchMap((user: User) =>
        this.userService.create(user).pipe(
          map(() => NotificationAction.showNotificationSuccess({
            message: `L'utilisateur '${user.firstname} ${user.lastname}' a bien été ajouté.`
          })),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.updateUser),
      switchMap((user: User) =>
        this.userService.update(user).pipe(
          map(() => NotificationAction.showNotificationSuccess({
            message: `L'utilisateur '${user.firstname} ${user.lastname}' a bien été modifié.`
          })),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserAction.deleteUser),
      switchMap((user: User) =>
        this.userService.delete(user).pipe(
          map(() => NotificationAction.showNotificationSuccess({
            message: `L'utilisateur '${user.firstname} ${user.lastname}' a bien été supprimé.`
          })),
          catchError((httpErrorResponse: HttpErrorResponse ) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  constructor(
    private actions$: Actions,
    private userService: UserService,
  ) {}
}
