<ion-content>
  <app-header></app-header>
  <main role="main">
    <app-breadcrumb></app-breadcrumb>
    <div class="fr-container--fluid fr-mb-2w fr-mb-md-0 sticky">
      <header role="banner" class="fr-header">
        <div class="fr-mx-7v">
          <nav class="fr-nav submenu" role="navigation" aria-label="Menu secondaire" data-fr-js-navigation="true">
            <div class="fr-grid-row fr-mx-n1w">
              <div class="fr-col-12 fr-col-md-4 fr-col-lg-3 fr-px-1w divider">
                <div class="submenu-logo"></div>
              </div>
              <div class="fr-col fr-col-lg-2 fr-px-1w">
                <button class="fr-nav__link fr-btn" [disabled]="!isManager" (click)="onCreate()">
                  <span class="submenu-icon fr-icon-add-circle-fill white fr-mr-2v" aria-hidden="true"></span>
                  <span class="submenu-entry">Créer un fait</span>
                  <span class="submenu-entry2">Créer</span>
                </button>
              </div>
              <div class="fr-col fr-col-lg-2 fr-px-1w">
                <button class="fr-nav__link fr-btn"
                  [disabled]="!isManager" (click)="onExport()">
                  <span class="submenu-icon fr-icon-download-fill white fr-mr-2v" aria-hidden="true"></span>
                  <span class="submenu-entry">Exporter la sélection</span>
                  <span class="submenu-entry2">Exporter</span>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
    <div class="fr-mx-7v">
      <div class="fr-grid-row">
        <div class="fr-col-12 fr-col-md-4 fr-col-lg-3">
          <nav class="fr-sidemenu fr-sidemenu--sticky-full-height fr-pr-0" role="navigation" aria-label="Menu latéral">
            <div class="fr-sidemenu__inner fr-container-left--responsive">
              <button class="fr-sidemenu__btn" aria-controls="fr-sidemenu-wrapper" aria-expanded="false"
                data-fr-js-collapse-button="true">Filtres
              </button>
              <div class="fr-collapse fr-pt-0 fr-pt-md-4w fr-pb-md-11w" id="fr-sidemenu-wrapper"
                data-fr-js-collapse="true">
                <div class="fr-sidemenu__title">Affiner la recherche</div>
                <button *ngIf="idFilter || nomenclatureFilter || userNameFilter || townFilter || networkIdFilter || startDateFilter || endDateFilter" (click)="onReset()"
                  class="fr-btn fr-btn--sm fr-btn--icon-left fr-btn--secondary fr-fi-close-line fr-mb-4v">Réinitialiser</button>
                <div class="fr-mb-4w fr-mt-3v fr-mt-md-0">
                  <div class="fr-mb-3w">
                    <div class="fr-input-group">
                      <label class="fr-label" for="idFilter">
                        Filtrer par n° de fait
                      </label>
                      <input class="fr-input fr-text--sm" type="number" id="idFilter" name="idFilter" [(ngModel)]="idFilter"
                        autocomplete="off">
                    </div>
                    <div class="fr-select-group">
                      <label class="fr-label" for="networkIdFilter">
                        Filtrer par type de fait
                      </label>
                      <select class="fr-select" id="nomenclatureFilter" name="nomenclatureFilter" [(ngModel)]="nomenclatureFilter">
                        <option value="" selected>Tous</option>
                        <option *ngFor="let nomenclatureId of nomenclatureIds;" value="{{ nomenclatureId }}">{{ nomenclatures[nomenclatureId] }}</option>
                      </select>
                    </div>
                    <div class="fr-select-group">
                      <label class="fr-label" for="networkIdFilter">
                        Filtrer par réseau
                      </label>
                      <select class="fr-select" id="networkIdFilter" name="networkIdFilter" [(ngModel)]="networkIdFilter">
                        <option value="" selected>Tous</option>
                        <option *ngFor="let network of networks;" value="{{ network.id }}">{{ network.name }}</option>
                      </select>
                    </div>
                    <div class="fr-select-group">
                      <label class="fr-label" for="townFilter">
                        Filtrer par commune
                      </label>
                      <select class="fr-select" id="townFilter" name="townFilter" [(ngModel)]="townFilter">
                        <option value="" selected>Toutes</option>
                        <option *ngFor="let town of towns;" value="{{ town }}">{{ parseTown(town).name }}</option>
                      </select>
                    </div>
                    <div class="fr-input-group">
                      <label class="fr-label" for="userNameFilter">
                        Filtrer par agent
                      </label>
                      <input class="fr-input fr-text--sm" type="text" id="userNameFilter" name="userNameFilter" [(ngModel)]="userNameFilter"
                        autocomplete="off">
                    </div>
                    <div class="fr-input-group">
                      <label class="fr-label" for="startDateFilter">
                        Filtrer par date de début
                      </label>
                      <input class="fr-input fr-text--sm" type="date" id="startDateFilter" name="startDateFilter"
                        [(ngModel)]="startDateFilter" autocomplete="off">
                    </div>
                    <div class="fr-input-group">
                      <label class="fr-label" for="endDateFilter">
                        Filtrer par date de fin
                      </label>
                      <input class="fr-input fr-text--sm" type="date" id="endDateFilter" name="endDateFilter"
                        [(ngModel)]="endDateFilter" autocomplete="off">
                    </div>
                  </div>
                  <div class="fr-container--fluid">
                    <button (click)="onApply()" class="fr-btn fr-col-12 fr-center">Afficher les résultats</button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div class="fr-container-right--responsive fr-col-12 fr-col-md-8 fr-col-lg-9">
          <div class="fr-container--fluid" id="content">
            <section class="fr-grid-row fr-mt-4w">
              <div class="fr-col-12">
                <h2 class="fr-mt-0 fr-pb-4w fr-mb-0 divider">
                  Liste des faits ({{ factCount$ | async }})
                </h2>
              </div>
              <div class="fr-col-12">
                <div class="fr-table fr-table--bordered fr-table--condensed fr-table--no-caption fr-table--fit fr-mb-6v">
                  <table>
                    <caption>Liste des faits</caption>
                    <thead>
                      <tr>
                        <th scope="col">N° du fait</th>
                        <th scope="col">Réseau</th>
                        <th scope="col">Commune</th>
                        <th scope="col">Saisi par</th>
                        <th scope="col">Type de fait</th>
                        <th scope="col">Date du fait</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="!hasFacts">
                        <td colspan="9">Aucun fait n'a été trouvé.</td>
                      </tr>
                      <tr
                        *ngFor="let fact of facts$ | async | paginate: { itemsPerPage: limit, currentPage: page, totalItems: factCount$ | async }">
                        <td class="fit">{{ fact.id }}</td>
                        <td>{{ fact.networkName }}</td>
                        <td>{{ parseTown(fact.town).name }}</td>
                        <td>{{ fact.userName }}</td>
                        <td>{{ nomenclatures[fact.nomenclature] }}</td>
                        <td class="fit">{{ fact.date | date:'dd/MM/yy à HH\'h\'mm' }}</td>
                        <td class="fit">
                          <button (click)="onDeleteFact(fact)" class="fr-btn fr-btn--sm fr-icon-delete-line fr-btn--secondary"
                            title="Supprimer le fait">
                            Supprimer
                          </button>
                          <button (click)="onUpdateFact(fact)" class="fr-btn fr-btn--sm fr-icon-pencil-fill fr-btn--secondary fr-ml-1w"
                            title="Modifier le fait">
                            Mettre à jour
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="fr-col-12">
                <nav role="navigation" class="fr-pagination fr-center" aria-label="Pagination">
                  <pagination-template #p="paginationApi" (pageChange)="onPageChanged($event)">
                    <ul class="fr-pagination__list fr-center">
                      <li *ngIf="!p.isFirstPage()">
                        <button (click)="p.previous()"
                          class="fr-pagination__link fr-pagination__link--prev fr-pagination__link--lg-label">
                          Page précédente
                        </button>
                      </li>
                      <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                        <button *ngIf="p.getCurrent() !== page.value" class="fr-pagination__link"
                          (click)="p.setCurrent(page.value)">
                          <span>{{ page.label }}</span>
                        </button>
                        <button *ngIf="p.getCurrent() === page.value" class="fr-pagination__link" aria-current="page">
                          <span>{{ page.label }}</span>
                        </button>
                      </li>
                      <li *ngIf="!p.isLastPage()">
                        <button
                          class="fr-pagination__link fr-pagination__link--next fr-pagination__link--lg-label pagination-next"
                          (click)="p.next()" [class.disabled]="p.isLastPage()">
                          Page suivante
                        </button>
                      </li>
                    </ul>
                  </pagination-template>
                </nav>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</ion-content>