import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard   {

  constructor(
    private userService: UserService,
    private router: Router) { }

  canActivate(_next: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.isAuthenticated().pipe(
      switchMap((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          this.router.navigateByUrl('/', { replaceUrl: true });
          return of(false);
        }
        return of(true);
      }),
      catchError(() => {
        this.router.navigateByUrl('/', { replaceUrl: true });
        return of(false);
      })
    );
  }
}
