import { createReducer, on } from '@ngrx/store';
import * as FactAction from './fact.actions';
import { Fact } from './fact.model';

export const featureKey = 'fact';

export interface State {
  facts: Fact[];
  factCount: number;
  errorMessage: string;
}

export const initialState: State = {
  facts: [],
  factCount: 0,
  errorMessage: null
};

export const reducer = createReducer(
  initialState,

  on(FactAction.getFactsSuccess, (state, action) => ({
    ...state,
    facts: action.facts,
    factCount: action.factCount,
  })),

);
