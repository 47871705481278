import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CsrfInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // On récupère le jeton CSRF depuis le cookie
    const csrfToken = this.getCookie('XSRF-TOKEN');

    // Ajout du jeton CSRF uniquement pour les requêtes POST, PUT, DELETE
    if (csrfToken && ['POST', 'PUT', 'DELETE'].includes(req.method)) {
      const modifiedReq = req.clone({
        setHeaders: {
          'X-XSRF-TOKEN': csrfToken
        }
      });
      return next.handle(modifiedReq);
    }

    return next.handle(req);
  }

  // Méthode pour récupérer le cookie
  private getCookie(name: string): string | null {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
}
