import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { StorageService } from 'src/app/core/services/storage.service';
import { FactPerson } from 'src/app/store/fact/fact-person.model';
import { User } from 'src/app/store/user/user.model';

@Component({
  selector: 'app-modify-perpetrator-modal',
  templateUrl: './modify-perpetrator-modal.component.html',
})
export class ModifyPerpetratorModalComponent implements OnInit {

  @Input() currentPerpetrator: FactPerson;
  createForm: UntypedFormGroup;
  isSubmitted = false;
  currentUser: User;
  isAgent = false;
  isOther = false;

  constructor(
    private modalController: ModalController,
    private storageService: StorageService,
    private formBuilder: UntypedFormBuilder) {
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.isAgent = this.currentPerpetrator.quality === 'Agent';
    this.isOther = this.currentPerpetrator.quality === 'Autre';
    this.createForm = this.formBuilder.group({
      quality: [this.currentPerpetrator.quality, [
        Validators.required,
      ]],
      function: [this.currentPerpetrator.function, [
        Validators.required,
      ]],
      gender: [this.currentPerpetrator.gender, [
        Validators.required,
      ]],
      count: [this.currentPerpetrator.count, [
        Validators.required,
        Validators.min(0),
      ]],
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.createForm.valid) {
      return false;
    }

    const person = new FactPerson({
      ...this.currentPerpetrator,
      quality: this.createForm.controls.quality.value,
      function: this.createForm.controls.function.value,
      gender: this.createForm.controls.gender.value,
      count: this.createForm.controls.count.value,
    })

    this.modalController.dismiss(person);
  }

  onChangeQuality(quality) {
    this.isAgent = quality === 'Agent';
    this.isOther = quality === 'Autre';
    if (this.isAgent) {
      this.createForm.controls.function.setValue('Inconnu');
    }
  }

  onDismiss() {
    this.modalController.dismiss();
  }

}
