<div class="inner-content">
  <div class="fr-modal__body">
    <div class="fr-modal__header"></div>
    <div class="fr-modal__content">
      <h1 id="fr-modal-2-title" class="fr-modal__title">
        Ajout d'un bien
      </h1>
      <form *ngIf="createForm" [formGroup]="createForm" novalidate>
      
        <div class="fr-select-group">
          <label class="fr-label" for="quality">Qualité du bien*</label>
          <select (change)="onChangeQuality($event.target.value)" class="fr-select" id="quality" name="quality"
            formControlName="quality">
            <option value="Bien de l'entreprise">Bien de l'entreprise</option>
            <option value="Bien privé">Bien privé (du voyageur, commerce, véhicule…)</option>
            <option value="Bien de l'agent">Bien de l'agent</option>
            <option value="Autre">Autre</option>
            <option value="Inconnu">Je ne sais pas</option>
          </select>
          <p *ngIf="isSubmitted && createForm.controls?.quality?.errors?.required" class="fr-error-text">
            La qualité du bien est obligatoire.
          </p>
        </div>
      
        <div *ngIf="isEnterprise" class="fr-select-group">
          <label class="fr-label" for="type">Type*</label>
          <select class="fr-select" id="type" name="type" formControlName="type">
            <option value="Installation fixe">Installation fixe</option>
            <option value="Matériel roulant">Matériel roulant</option>
          </select>
        </div>
      
      </form>
    </div>
    <div class="fr-modal__footer">
      <ul
        class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
        <li>
          <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Annuler</button>
        </li>
        <li>
          <button (click)="onSubmit()" type="submit" class="fr-btn">Ajouter</button>
        </li>
      </ul>
    </div>
  </div>
</div>
