import { createAction, props } from '@ngrx/store';
import { FactQuery } from 'src/app/core/models/fact-query.model';
import { Fact } from './fact.model';

export const createFact = createAction('[Fact] Create fact', props<Fact>());
export const updateFact = createAction('[Fact] Update fact', props<Fact>());
export const deleteFact = createAction('[Fact] Delete fact', props<Fact>());
export const getFacts = createAction('[Fact] Get facts', props<FactQuery>());
export const getFactsSuccess = createAction('[Fact] Get facts success', props<{
    facts: Fact[];
    factCount: number;
}>());
