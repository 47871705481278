import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { deleteNetwork } from 'src/app/store/network/network.actions';
import { Network } from 'src/app/store/network/network.model';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent {

  @Input() network: Network;

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController) {
  }

  onSubmit() {
    this.store.dispatch(deleteNetwork(this.network));
    this.modalController.dismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }
}
