<div class="inner-content">
  <div class="fr-modal__body">
    <div class="fr-modal__header"></div>
    <div class="fr-modal__content">
      <h1 id="fr-modal-2-title" class="fr-modal__title">
        Ajout d'un utilisateur
      </h1>
      <form *ngIf="createForm" [formGroup]="createForm" novalidate>
        <div class="fr-input-group">
          <label class="fr-label" for="firstname">
            Prénom*
          </label>
          <input class="fr-input" type="text" id="firstname" name="firstname" formControlName="firstname">
          <p *ngIf="isSubmitted && createForm.controls?.firstname?.errors?.required" class="fr-error-text">
            Le prénom de l'utilisateur est obligatoire.
          </p>
          <p *ngIf="isSubmitted && createForm.controls?.firstname?.errors?.minlength" class="fr-error-text">
            Le prénom de l'utilisateur doit avoir au moins 2 caractères.
          </p>
        </div>
        <div class="fr-input-group">
          <label class="fr-label" for="lastname">
            Nom*
          </label>
          <input class="fr-input" type="text" id="lastname" name="lastname" formControlName="lastname">
          <p *ngIf="isSubmitted && createForm.controls?.lastname?.errors?.required" class="fr-error-text">
            Le nom de l'utilisateur est obligatoire.
          </p>
          <p *ngIf="isSubmitted && createForm.controls?.lastname?.errors?.minlength" class="fr-error-text">
            Le nom de l'utilisateur doit avoir au moins 2 caractères.
          </p>
        </div>
        <div class="fr-input-group">
          <label class="fr-label" for="email">
            Adresse email*
          </label>
          <input class="fr-input" type="text" id="email" name="email" formControlName="email">
          <p *ngIf="isSubmitted && createForm.controls?.email?.errors?.required" class="fr-error-text">
            L'adresse email de l'utilisateur est obligatoire.
          </p>
        </div>
        <div class="fr-select-group">
          <label class="fr-label" for="role">
            Qualité*
          </label>
          <select class="fr-select" id="role" name="role" formControlName="role"
            (change)="onChange($event.target.value)">
            <ng-container *ngFor="let role of roles">
              <option *ngIf="role.active" [value]="role.name" [attr.selected]="role.selected ? true: null">{{ role.name
                }}</option>
            </ng-container>
          </select>
          <p *ngIf="isSubmitted && createForm.controls?.role?.errors?.required" class="fr-error-text">
            Le rôle de l'utilisateur est obligatoire.
          </p>
        </div>
        <div *ngIf="!isAdmin" class="fr-select-group">
          <label class="fr-label" for="networkIds">
            Réseau(x)*
          </label>
          <ng-select id="networkIds" name="networkIds" formControlName="networkIds" [items]="networks$ | async" multiple="true"
            bindLabel="name" bindValue="id" dropdownPosition="top" loadingText="Chargement..." clearAllText="Tout retirer"
            notFoundText="Aucun réseau trouvé." typeToSearchText="Veuillez entrer au moins 2 caractères" [hideSelected]="true">
          </ng-select>
        </div>
        <p *ngIf="isSubmitted && createForm.controls?.networkIds?.errors?.required" class="fr-error-text">
          L'utilisateur doit être positionné sur au moins un réseau.
        </p>
      </form>
    </div>
    <div class="fr-modal__footer">
      <ul
        class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
        <li>
          <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Annuler</button>
        </li>
        <li>
          <button (click)="onSubmit()" type="submit" class="fr-btn">Ajouter</button>
        </li>
      </ul>
    </div>
  </div>
</div>