import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Town } from 'src/app/core/models/town.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { TownService } from 'src/app/core/services/town.service';
import { AppState } from 'src/app/store/app.state';
import { updateNetwork } from 'src/app/store/network/network.actions';
import { Network } from 'src/app/store/network/network.model';
import { User } from 'src/app/store/user/user.model';
import { RemoveModalComponent } from '../remove-modal/remove-modal.component';

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
})
export class UpdateModalComponent implements OnInit {

  @Input() network: Network;
  towns$: Observable<Town[]>;
  townInput$ = new Subject<string>();
  townLoading = false;
  currentUser: User;
  updateForm: UntypedFormGroup;
  isSubmitted = false;
  selectedTowns = [];
  transports = [
    'Autre',
    'Bateau',
    'BHNS',
    'Bus',
    'Car',
    'En dehors des modes de transports',
    'Funiculaire',
    'Métro',
    'PMR',
    'RER',
    'Téléphérique',
    'Train',
    'Tram-train',
    'Tramway'
  ];

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private townService: TownService) {
  }

  async ngOnInit() {
    this.loadTowns();
    this.currentUser = new User(await this.storageService.get('User'));
    if (this.currentUser.isSupervisor) {
      this.updateForm = this.formBuilder.group({
        name: [this.network.name, [
          Validators.required,
          Validators.minLength(2)
        ]],
        group: [this.network.group, [
          Validators.required,
          Validators.minLength(2)
        ]],
        authority: [this.network.authority, [
          Validators.required,
          Validators.minLength(2)
        ]],
      });
    }
    else if (this.currentUser.isManager) {
      this.updateForm = this.formBuilder.group({
        transports: [this.network.transports, []],
        towns: [this.network.towns
          ? this.network.towns.map((_town) => {
            return {
              name: _town.split('[')[0].trim(),
              value: _town
            }
          }) 
        : [], []],
      });
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.updateForm.valid) {
      return false;
    }

    if (this.currentUser.isSupervisor) {
      this.network = {
        ...this.network,
        name: this.updateForm.value.name,
        group : this.updateForm.value.group,
        authority: this.updateForm.value.authority,
      };
    }
    else if (this.currentUser.isManager) {
      this.network = {
        ...this.network,
        transports: this.updateForm.value.transports
          ? [...this.updateForm.value.transports].sort()
          : [],
        towns: this.updateForm.value.towns
          ? this.updateForm.value.towns.map((_town: any) => typeof _town === 'object'
              ? _town.value
              : _town).sort()
          : [],
      };
    }

    this.store.dispatch(updateNetwork(this.network));
    this.modalController.dismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  async onClearTransports() {
    const modal = await this.modalController.create({
      component: RemoveModalComponent,
      cssClass: 'auto-height'
    });

    modal.onDidDismiss().then((event) => {
        if (event.data && event.data.success) {
          this.updateForm.controls['transports'].setValue(null);
        }
    });

    await modal.present();
    return false;
  }

  async onClearTowns() {
    const modal = await this.modalController.create({
      component: RemoveModalComponent,
      cssClass: 'auto-height'
    });

    modal.onDidDismiss().then((event) => {
        if (event.data && event.data.success) {
          this.updateForm.controls['towns'].setValue(null);
        }
    });

    await modal.present();
    return false;
  }
  
  compareTown(townA, townB): boolean {
    return townA == townB;
  }

  private loadTowns() {
    this.towns$ = concat(
        of([]),
        this.townInput$.pipe(
            distinctUntilChanged(),
            debounceTime(400),
            tap(() => this.townLoading = true),
            switchMap((name) => {
              if (name) {
                return this.townService.find(name).pipe(
                  catchError(() => of([])),
                  switchMap((_towns) => {
                    this.townLoading = false;
                    const selectedTowns = this.updateForm.value.towns?.map((_town) => typeof _town === 'object' ? _town.value : _town) ?? [];
                    return of(_towns.filter((_town) => !selectedTowns.includes(_town.name) && !selectedTowns.includes(_town.value)));
                  })
                );
              }
              else {
                this.townLoading = false;
                return of([]);
              }
            })
        )
    );
  }

}
