import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from 'src/app/store/app.state';
import { getNetworks } from 'src/app/store/network/network.actions';
import { Network } from 'src/app/store/network/network.model';
import { Notification } from 'src/app/store/notification/notification.model';
import { selectNetworkCount, selectNetworks } from 'src/app/store/network/network.selectors';
import { selectNotification } from 'src/app/store/notification/notification.selectors';
import { NotificationService } from 'src/app/core/services/notification.service';
import { CreateModalComponent } from './components/create-modal/create-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { UpdateModalComponent } from './components/update-modal/update-modal.component';
import { User } from 'src/app/store/user/user.model';
import { StorageService } from 'src/app/core/services/storage.service';
import { NetworkHelper } from 'src/app/core/helpers/network.helper';

@Component({
  selector: 'app-networks',
  templateUrl: './networks.page.html',
})
export class NetworksPage implements OnInit {

  networks$: Observable<Network[]> = this.store.select(selectNetworks);
  networkCount$: Observable<number> = this.store.select(selectNetworkCount);
  notification$: Observable<any> = this.store.select(selectNotification);
  currentUser: User;
  hasNetworks = false;
  page = 1;
  limit = 10;
  name = '';
  group = '';
  authority = '';

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController,
    private notificationService: NotificationService,
    private storageService: StorageService) {
      this.networkCount$.subscribe((count) => this.hasNetworks = count > 0);
      this.notification$.subscribe(async (notification: Notification) => {
        this.notificationService.show(notification);
        if (notification && notification.type === 'SUCCESS') {
          this.refreshData();
        }
      });
  }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.refreshData();
  }

  onApply() {
    this.page = 1;
    this.refreshData();
  }

  onReset() {
    this.page = 1;
    this.name = '';
    this.group = '';
    this.authority = '';
    this.refreshData();
  }

  onPageChanged(event) {
    this.page = event;
    this.refreshData();
  }

  onPageSizeChanged(event) {
    this.page = 1;
    this.limit = event.target.value;
    this.refreshData();
  }

  async onCreateNetwork() {
     const modal = await this.modalController.create({
      component: CreateModalComponent,
      cssClass: 'auto-height'
    });
    await modal.present();
  }

  async onDeleteNetwork(network: Network) {
    const modal = await this.modalController.create({
      component: DeleteModalComponent,
      cssClass: 'auto-height',
      componentProps : { network }
    });
    await modal.present();
  }

  async onUpdateNetwork(network: Network) {
    const modal = await this.modalController.create({
      component: UpdateModalComponent,
      cssClass: 'auto-height',
      componentProps : { network }
    });
    await modal.present();
  }

  refreshData() {
    this.store.dispatch(getNetworks({
      page: this.page,
      limit: this.limit,
      name: this.name,
      group: this.group,
      authority: this.authority
    }));
  }

  parseTowns(towns) {
    return towns.map((_town) => NetworkHelper.parseTown(_town).name);
  }
}
