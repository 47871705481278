<ion-content>
<div class="inner-content">
  <div class="fr-modal__body">
    <div class="fr-modal__header"></div>
    <div class="fr-modal__content">
      <h1 id="fr-modal-2-title" class="fr-modal__title">
        Modification d'une victime
      </h1>
      <form *ngIf="updateForm" [formGroup]="updateForm" novalidate>
      
        <div class="fr-form-group">
          <fieldset class="fr-fieldset fr-fieldset--inline">
            <legend class="fr-fieldset__legend">
              Qualité*
            </legend>
            <div class="fr-fieldset__content fr-fieldset__group">
              <div class="fr-radio-group">
                <input (change)="onChangeQuality($event.target.value)" type="radio" id="quality-3" name="quality"
                  formControlName="quality" value="Voyageur">
                <label class="fr-label" for="quality-3">Voyageur</label>
              </div>
              <div class="fr-radio-group">
                <input (change)="onChangeQuality($event.target.value)" type="radio" id="quality-1" name="quality" formControlName="quality" value="Agent">
                <label class="fr-label" for="quality-1">Agent</label>
              </div>
              <div class="fr-radio-group">
                <input (change)="onChangeQuality($event.target.value)" type="radio" id="quality-4" name="quality" formControlName="quality" value="Autre">
                <label class="fr-label" for="quality-4">Autre</label>
              </div>
            </div>
          </fieldset>
          <p *ngIf="isOther" class="fr-hint-text fr-mt-5v">Par exemple : prestataires, concessionnaires, secours, forces de
            l'ordre, ...</p>
        </div>

        <div class="fr-form-group">
          <fieldset class="fr-fieldset fr-fieldset--inline">
            <legend class="fr-fieldset__legend">
              Sexe*
            </legend>
            <div class="fr-fieldset__content fr-fieldset__group">
              <div class="fr-radio-group">
                <input type="radio" id="gender-2" name="gender" formControlName="gender" value="Femme">
                <label class="fr-label" for="gender-2">Femme</label>
              </div>
              <div class="fr-radio-group">
                <input type="radio" id="gender-3" name="gender" formControlName="gender" value="Homme">
                <label class="fr-label" for="gender-3">Homme</label>
              </div>
              <div class="fr-radio-group">
                <input type="radio" id="gender-1" name="gender" formControlName="gender" value="Inconnu">
                <label class="fr-label" for="gender-1">Je ne sais pas</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div *ngIf="isAgent" class="fr-form-group">
          <fieldset class="fr-fieldset">
            <legend class="fr-fieldset__legend">
              Fonction*
            </legend>
            <div class="fr-fieldset__content">
              <div class="fr-radio-group fr-radio-group--sm">
                <input type="radio" id="function-8" name="function" formControlName="function" value="Inconnu">
                <label class="fr-label" for="function-8">Je ne sais pas</label>
              </div>
              <div class="fr-radio-group fr-radio-group--sm">
                <input type="radio" id="function-1" name="function" formControlName="function" value="Agent de conduite">
                <label class="fr-label" for="function-1">Agent de conduite</label>
              </div>
              <div class="fr-radio-group fr-radio-group--sm">
                <input type="radio" id="function-2" name="function" formControlName="function" value="Agent de contrôle">
                <label class="fr-label" for="function-2">Agent de contrôle</label>
              </div>
              <div class="fr-radio-group fr-radio-group--sm">
                <input type="radio" id="function-3" name="function" formControlName="function" value="Agent de médiation">
                <label class="fr-label" for="function-3">Agent de médiation</label>
              </div>
              <div class="fr-radio-group fr-radio-group--sm">
                <input type="radio" id="function-4" name="function" formControlName="function" value="Agent de régulation">
                <label class="fr-label" for="function-4">Agent de régulation</label>
              </div>
              <div class="fr-radio-group fr-radio-group--sm">
                <input type="radio" id="function-5" name="function" formControlName="function"
                  value="Agent de sécurité/surveillance">
                <label class="fr-label" for="function-5">Agent de sécurité/surveillance</label>
              </div>
              <div class="fr-radio-group fr-radio-group--sm">
                <input type="radio" id="function-6" name="function" formControlName="function" value="Agent de vente">
                <label class="fr-label" for="function-6">Agent de vente</label>
              </div>
              <div class="fr-radio-group fr-radio-group--sm">
                <input type="radio" id="function-7" name="function" formControlName="function" value="Autre">
                <label class="fr-label" for="function-7">Autre</label>
              </div>
            </div>
          </fieldset>
        </div>

        <div class="fr-form-group">
          <fieldset class="fr-fieldset">
            <legend class="fr-fieldset__legend">
              Nombre de personnes*
            </legend>
            <input class="fr-input" type="number" id="count" name="count" formControlName="count">
          </fieldset>
        </div>
      
      </form>
    </div>
    <div class="fr-modal__footer">
      <ul
        class="fr-btns-group fr-btns-group--right fr-btns-group--inline-reverse fr-btns-group--inline-lg fr-btns-group--icon-left">
        <li>
          <button (click)="onDismiss()" class="fr-btn fr-btn--secondary">Annuler</button>
        </li>
        <li>
          <button (click)="onSubmit()" type="submit" class="fr-btn">Modifier</button>
        </li>
      </ul>
    </div>
  </div>
</div>
</ion-content>