<app-header></app-header>
<ion-content>
  <main role="main" class="main">
    <div class="fr-container">
      <div class="fr-grid-row fr-grid-row--center">
        <div class="fr-col-md-6 fr-col-12 fr-mt-6w">
          <div class="fr-callout fr-callout--max-height">
            <div class="fr-callout__text">
              Bienvenue sur le portail de gestion d'App'ISIS.
              <br /><br />
              Intuitif et sécurisé, il permet :
              <ul>
                <li>la gestion des utilisateurs d'App'ISIS pour votre réseau,</li>
                <li>la gestion des faits saisis par vos agents,</li>
                <li>la création des fichiers à importer dans l'outil ISIS.</li>
              </ul>
            </div>
            <app-login-form></app-login-form>
          </div>
        </div>
      </div>
    </div>
  </main>
</ion-content>
