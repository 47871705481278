<ion-content>
  <app-header></app-header>
  <main role="main" class="">
    <app-breadcrumb></app-breadcrumb>
    <div class="fr-container">
      <div class="fr-grid-row overflow-hidden">
        <div id="content" class="fr-col-12 fr-pl-4v">
          <div class="fr-container--fluid fr-mt-2w">
            <h3 class="fr-text--lead fr-mb-4v">Mentions légales</h3>
            <h4 class="fr-text--lg fr-mb-1w">Editeur du site internet</h4>
            <p class="fr-text--xs fr-mb-2w">
              Ministère de la Transition écologique et de la Cohésion des territoires
              <br/>
              Grande Arche - paroi Sud / Tour Séquoia
              <br/>
              92055 La Défense
              <br/>
              France
            </p>
            <h4 class="fr-text--lg fr-mb-1w">Directeur de la publication et, le cas échéant, du responsable de la rédaction</h4>
            <p class="fr-text--xs fr-mb-2w">
              Fabrice Fussy, chef de l'Observatoire national de la délinquance dans les transports (ONDT)
              <br />
              Adresse postale : Ministère de la Transition écologique et de la Cohésion des territoires
              <br />
              Grande Arche - paroi Sud / Tour Séquoia
              <br />
              92055 La Défense
              <br />
              France
              <br />
              Mail : <a href="mailto:projet-isis.fcd5.dst.dgitm@developpement-durable.gouv.fr">projet-isis.fcd5.dst.dgitm&#64;developpement-durable.gouv.fr</a>
            </p>
            <h4 class="fr-text--lg fr-mb-1w">Hébergeur du site internet</h4>
            <p class="fr-text--xs fr-mb-2w">
              Direction du Numérique, Ministère de la Transition écologique et de la Cohésion des territoires
              <br />
              Grande Arche - paroi Sud / Tour Séquoia
              <br />
              92055 La Défense
              <br />
              France
            </p>
            <h4 class="fr-text--lg fr-mb-1w">Traitement des données à caractère personnel</h4>
            <p class="fr-text--xs">
              Le traitement des données à caractère personnel saisies dans App'ISIS, conformément au RGPD (Règlement Général sur la
              Protection des Données) est en cours d'élaboration. Cette rubrique sera actualisée prochainement.
            </p>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</ion-content>