import { createAction, props } from '@ngrx/store';
import { User } from './user.model';

export const getUsers = createAction('[User] Get users', props<{
    limit: number;
    page: number;
    name: string;
}>());
export const getUsersSuccess = createAction('[User] Get users success', props<{
    users: User[];
    userCount: number;
}>());

export const createUser = createAction('[User] Create user', props<User>());
export const updateUser = createAction('[User] Update user', props<User>());
export const deleteUser = createAction('[User] Delete user', props<User>());
