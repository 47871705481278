import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import * as FactAction from './fact.actions';
import * as NotificationAction from '../notification/notification.actions';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Response } from 'src/app/core/models/response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { FactQuery } from 'src/app/core/models/fact-query.model';
import { FactService } from 'src/app/core/services/fact.service';
import { Fact } from './fact.model';
import { Router } from '@angular/router';

@Injectable()
export class FactEffects {

  getFacts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FactAction.getFacts),
      switchMap((query: FactQuery) =>
        this.factService.get(query).pipe(
          map((response: Response<Fact[]>) => FactAction.getFactsSuccess({
            facts: response.data,
            factCount: response.count
          })),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  createFact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FactAction.createFact),
      switchMap((fact: Fact) =>
        this.factService.create(fact).pipe(
          map(() => {
            this.router.navigateByUrl('/faits', { replaceUrl: true });
            return NotificationAction.showNotificationSuccess({
              message: `Le fait a été créé avec succès.`
            })
          }),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  updateFact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FactAction.updateFact),
      switchMap((fact: Fact) =>
        this.factService.update(fact).pipe(
          map(() => {
            this.router.navigateByUrl('/faits', { replaceUrl: true });
            return NotificationAction.showNotificationSuccess({
              message: `Le fait a été mis à jour avec succès.`
            })
          }),
          catchError((httpErrorResponse: HttpErrorResponse) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  deleteFact$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FactAction.deleteFact),
      switchMap((fact: Fact) =>
        this.factService.delete(fact).pipe(
          map(() => NotificationAction.showNotificationSuccess({
            message: `Le fait a été supprimé avec succès.`
          })),
          catchError((httpErrorResponse: HttpErrorResponse ) =>
            of(NotificationAction.showNotificationError({ message: httpErrorResponse?.error?.message } ))
          ),
        )
      )
    ),
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private factService: FactService
  ) {}
}
