<footer class="fr-footer fr-p-0 fr-text--center" role="contentinfo" id="footer">
  <div class="fr-container">
    <div class="fr-footer__bottom fr-mt-0">
      <ul class="fr-footer__bottom-list">
        <li class="fr-footer__bottom-item">
          <a class="fr-footer__bottom-link" href="/accessibilite">Accessibilité</a>
        </li>
        <li class="fr-footer__bottom-item">
          <a class="fr-footer__bottom-link" href="/mentions-legales">Mentions légales</a>
        </li>
      </ul>
    </div>
  </div>
</footer>