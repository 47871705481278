import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  exludedUrls = [
    'geo.api.gouv.fr'
  ];

  constructor(
    private loading: LoadingService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const match = this.exludedUrls.find(_excludedUrl => {
      if (request.url.includes(_excludedUrl)) {
        return true;
      }
    });

    if (match !== undefined) {
      return next.handle(request);
    }

    this.loading.setLoading(true, request.url);
    return next.handle(request).pipe(
      catchError((error) => {
        this.loading.setLoading(false, request.url);
        return throwError(error);
    }))
    .pipe(map<HttpEvent<any>, any>((httpEvent: HttpEvent<any>) => {
      if (httpEvent instanceof HttpResponse) {
        this.loading.setLoading(false, request.url);
      }
      return httpEvent;
    }));
  }
}
