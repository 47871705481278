<form (ngSubmit)="login()" class="fr-mb-0">
  <button class="fr-btn full-width-button">Se connecter</button>
  <div class="fr-center fr-mt-2w">
    <a href="mailto:projet-isis.fcd5.dst.dgitm@developpement-durable.gouv.fr?subject=AppISIS - Demande d'assistance"
      class="fr-link fr-center">Besoin d'aide ?</a>
    <ul class="fr-footer__bottom-list fr-pb-0">
      <li class="fr-footer__bottom-item">
        <a class="fr-footer__bottom-link" href="/accessibilite">Accessibilité</a>
      </li>
      <li class="fr-footer__bottom-item">
        <a class="fr-footer__bottom-link" href="/mentions-legales">Mentions légales</a>
      </li>
    </ul>
  </div>
</form>