<ion-header>
<base href="/">
<header role="banner" class="fr-header">
  <div class="fr-header__body">
    <div class="fr-mx-7v">
      <div class="fr-header__body-row">
        <div class="fr-header__brand fr-enlarge-link">
          <div class="fr-header__brand-top">
            <div class="fr-header__logo">
              <p class="fr-logo">
                Ministère
                <br>chargé des
                <br>transports
              </p>
            </div>
            <div class="fr-header__operator">
              <img src="assets/isis-logo.svg" class="fr-responsive-img" style="max-width:9.0625rem;"
                alt="App'ISIS logo." />
            </div>
            <div class="fr-header__navbar">
              <button (click)="onMenu()" class="fr-btn--menu fr-btn" data-fr-opened="false" aria-controls="MenuDialog" aria-haspopup="menu"
                title="Menu" data-fr-js-modal-button="true">
                Menu
              </button>
            </div>
          </div>
          <div class="fr-header__service">
            <a href="/" title="Portail de gestion App'ISIS">
              <p class="fr-header__service-title">Portail de gestion App'ISIS</p>
            </a>
          </div>
        </div>
        <div class="fr-header__tools">
          <div class="fr-header__tools-links">
            <ul class="fr-links-group">
              <li *ngIf="(isAuthenticated$ | async)" >
                <button class="fr-link fr-icon-user-line">{{ currentUser.name }}</button>
              </li>
              <li *ngIf="(isAuthenticated$ | async)">
                <a href="mailto:projet-isis.fcd5.dst.dgitm@developpement-durable.gouv.fr?subject=AppISIS - Demande d'assistance" class="fr-link fr-icon-question-line">Besoin d'aide ?</a>
              </li>
              <li *ngIf="(isAuthenticated$ | async)" >
                <button (click)="logout()" class="fr-link fr-fi-lock-line deconnection-button">Se déconnecter</button>
              </li>
              <li>
                <span class="fr-link fr-version">{{ version }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="fr-header__menu fr-modal" id="MenuDialog" aria-labelledby="fr-btn-menu-mobile-4">
    <div class="fr-mx-7v">
      <button class="fr-link--close fr-link" aria-controls="MenuDialog">Fermer</button>
      <div class="fr-header__menu-links"></div>
      <nav class="fr-nav" id="navigation-869" role="navigation" aria-label="Menu principal">
        <ul  *ngIf="(isAuthenticated$ | async)" class="fr-nav__list">
          <li *ngIf="isManager" class="fr-nav__item">
            <a class="fr-nav__link" replaceUrl="true" href="/faits"
            [attr.aria-current]="this.href === '/faits' ? '{{ page }}' : null" target="_self">Faits</a>
          </li>
          <li *ngIf="!isAgent" class="fr-nav__item">
            <a class="fr-nav__link" replaceUrl="true" href="/utilisateurs"
            [attr.aria-current]="this.href === '/utilisateurs' ? '{{ page }}' : null" target="_self">Utilisateurs</a>
          </li>
          <li *ngIf="isSupervisor || isManager" class="fr-nav__item">
            <a class="fr-nav__link" replaceUrl="true" href="/reseaux"
            [attr.aria-current]="this.href === '/reseaux' ? '{{ page }}' : null" target="_self">Réseaux</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</header>
</ion-header>