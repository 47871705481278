import { createAction, props } from '@ngrx/store';

export const showNotificationSuccess = createAction('[Notification] Show notification susscess', props<{
    message: string;
}>());

export const showNotificationError = createAction('[Notification] Show notification error', props<{
    message: string;
}>());

export const clearNotification = createAction('[Notification] Clear notification');
