import { formatDate } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { FactService } from 'src/app/core/services/fact.service';
import { StorageService } from 'src/app/core/services/storage.service';
import { AppState } from 'src/app/store/app.state';
import { FactGood } from 'src/app/store/fact/fact-good.model';
import { FactPerson } from 'src/app/store/fact/fact-person.model';
import { updateFact } from 'src/app/store/fact/fact.actions';
import { Fact } from 'src/app/store/fact/fact.model';
import { Network } from 'src/app/store/network/network.model';
import { User } from 'src/app/store/user/user.model';
import { AddGoodModalComponent } from '../add-good-modal/add-good-modal.component';
import { AddPerpetratorModalComponent } from '../add-perpetrator-modal/add-perpetrator-modal.component';
import { AddProtagonistModalComponent } from '../add-protagonist-modal/add-protagonist-modal.component';
import { AddVictimModalComponent } from '../add-victim-modal/add-victim-modal.component';
import { ModifyGoodModalComponent } from '../modify-good-modal/modify-good-modal.component';
import { ModifyPerpetratorModalComponent } from '../modify-perpetrator-modal/modify-perpetrator-modal.component';
import { ModifyProtagonistModalComponent } from '../modify-protagonist-modal/modify-protagonist-modal.component';
import { ModifyVictimModalComponent } from '../modify-victim-modal/modify-victim-modal.component';

@Component({
  selector: 'app-fact-update',
  templateUrl: './fact-update.component.html',
})
export class FactUpdateComponent implements OnInit {

  @Input() fact: Fact;
  currentUser: User;
  networks: Network[];
  towns: any[];
  transports: string[];
  persons: FactPerson[];
  perpetrators: FactPerson[];
  victims: FactPerson[];
  protagonists: FactPerson[];
  goods: FactGood[];
  nomenclatures: any[];
  updateForm: UntypedFormGroup;
  isSubmitted = false;

  constructor(
    private store: Store<AppState>,
    private factService: FactService,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private modalController: ModalController) { }

  async ngOnInit() {
    this.currentUser = new User(await this.storageService.get('User'));
    this.networks = this.currentUser.networks;
    this.nomenclatures = this.factService.getNomenclatures();
    this.updateForm = this.formBuilder.group({
      date: [formatDate(this.fact.date, 'yyyy-MM-dd', 'fr'), [
        Validators.required,
      ]],
      time: [formatDate(this.fact.date, 'HH:mm', 'fr'), [
        Validators.required,
      ]],
      networkId: [this.fact.networkId, [
        Validators.required,
      ]],
      town: [this.fact.town, [
        Validators.required,
      ]],
      transport: [this.fact.transport, [
        Validators.required,
      ]],
      stop: [this.fact.stop],
      line: [this.fact.line],
      priorityZone: [this.fact.priorityZone],
      nomenclature: [this.fact.nomenclature, [
        Validators.required,
      ]],
      serviceImpact: [this.fact.serviceImpact],
      offerModification: [this.fact.offerModification],
      departmentIntervention: [this.fact.departmentIntervention],
      policeIntervention: [this.fact.policeIntervention],
      emergencyIntervention: [this.fact.emergencyIntervention],
      complaint: [this.fact.complaint],
      weapon: [this.fact.weapon],
      cause: [this.fact.cause, [
        Validators.required,
      ]],
      persons: [this.persons, []],
      goods: [this.goods, []],
    });

    this.initializeForm();
  }

  initializeForm() {
    const fact = this.fact;
    const networkId = this.fact.networkId;
    this.updateForm.controls.networkId.setValue(networkId);
    this.onChangeNetwork(networkId);
    this.updateForm.controls.town.setValue(fact.town);
    this.updateForm.controls.transport.setValue(fact.transport);
    this.persons = fact.persons.map((person) => Object.assign({}, person));
    this.updateForm.controls.persons.setValue(this.persons);
    this.perpetrators = fact.persons.filter((_person) => _person.type == 'Auteur');
    this.victims = fact.persons.filter((_person) => _person.type == 'Victime');
    this.protagonists = fact.persons.filter((_person) => _person.type == 'Protagoniste');
    this.goods = fact.goods.map((good) => Object.assign({}, good));
    this.updateForm.controls.goods.setValue(this.goods);
  }

  onChangeNetwork(networkId: number) {
    this.refreshTowns(networkId);
    this.refreshTransports(networkId);
  }

  refreshTowns(networkId) {
    this.towns = this.currentUser.networks
      .filter((_network) => _network.id == networkId)
      .map((_network) => _network.towns ?? [])
      .reduce((_town) => _town)
      .sort((_townA, _townB) => _townA.localeCompare(_townB))
      .map((_town) => {
        return {
          name: _town.split('[')[0].trim(),
          value: _town
        }
      });

    this.towns?.length
      ? this.updateForm.controls.town.setValue(this.towns[0].value)
      : this.updateForm.controls.town.reset();
  }

  refreshTransports(networkId) {
    this.transports = this.currentUser.networks
      .filter((_network) => _network.id == networkId)
      .map((_network) => _network.transports ?? [])
      .reduce((_transport) => _transport)
      .sort((_transportA, _transportB) => _transportA.localeCompare(_transportB));

    this.transports?.length
      ? this.updateForm.controls.transport.setValue(this.transports[0])
      : this.updateForm.controls.transport.reset();
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.updateForm.valid) {
      return false;
    }

    this.store.dispatch(updateFact({
      id: this.fact.id,
      uuid: this.fact.uuid,
      ...this.updateForm.value,
    }));
    this.modalController.dismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }

  async onAddPerpetratorButtonClick() {
    const modal = await this.modalController.create({
      component: AddPerpetratorModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const perpetrator = data['data'] as FactPerson;
      if (perpetrator) {
        this.perpetrators.push(perpetrator);
        this.persons.push(perpetrator);
        this.updateForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  async onModifyPerpetratorButtonClick(perpetratorId: string) {
    const currentPerpetrator = this.perpetrators.filter((_perpertrator) => _perpertrator.id == perpetratorId)[0];
    const modal = await this.modalController.create({
      component: ModifyPerpetratorModalComponent,
      componentProps : { currentPerpetrator }
    });

    modal.onDidDismiss().then((data) => {
      const perpetrator = data['data'] as FactPerson;
      if (perpetrator) {
        const perpetratorIndex = this.perpetrators.findIndex((_perpetrator) =>  _perpetrator.id == perpetrator.id);
        this.perpetrators[perpetratorIndex] = perpetrator;

        const personIndex = this.persons.findIndex((_person) =>  _person.id == perpetrator.id);
        this.persons[personIndex] = perpetrator;

        this.updateForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  onRemovePerpetratorButtonClick(perpetratorId) {
    if (!perpetratorId) {
      return;
    }

    this.perpetrators = this.perpetrators.filter((_perpetrator) => _perpetrator.id != perpetratorId);
    this.persons = this.persons.filter((_person) => _person.id != perpetratorId);
    this.updateForm.controls.persons.setValue(this.persons);
  }

    async onAddVictimButtonClick() {
    const modal = await this.modalController.create({
      component: AddVictimModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const victim = data['data'] as FactPerson;
      if (victim) {
        this.victims.push(victim);
        this.persons.push(victim);
        this.updateForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  async onModifyVictimButtonClick(victimId: string) {
    const currentVictim = this.victims.filter((_victim) => _victim.id == victimId)[0];
    const modal = await this.modalController.create({
      component: ModifyVictimModalComponent,
      componentProps : { currentVictim }
    });

    modal.onDidDismiss().then((data) => {
      const victim = data['data'] as FactPerson;
      if (victim) {
        const victimIndex = this.victims.findIndex((_victim) =>  _victim.id == victim.id);
        this.victims[victimIndex] = victim;

        const personIndex = this.persons.findIndex((_person) =>  _person.id == victim.id);
        this.persons[personIndex] = victim;

        this.updateForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  onRemoveVictimButtonClick(victimId) {
    if (!victimId) {
      return;
    }

    this.victims = this.victims.filter((_victim) => _victim.id != victimId);
    this.persons = this.persons.filter((_person) => _person.id != victimId);
    this.updateForm.controls.persons.setValue(this.persons);
  }

  async onAddProtagonistButtonClick() {
    const modal = await this.modalController.create({
      component: AddProtagonistModalComponent,
    });

    modal.onDidDismiss().then((data) => {
      const protagonist = data['data'] as FactPerson;
      if (protagonist) {
        this.protagonists.push(protagonist);
        this.persons.push(protagonist);
        this.updateForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  async onModifyProtagonistButtonClick(protagonistId: string) {
    const currentProtagonist = this.protagonists.filter((_perpertrator) => _perpertrator.id == protagonistId)[0];
    const modal = await this.modalController.create({
      component: ModifyProtagonistModalComponent,
      componentProps : { currentProtagonist }
    });

    modal.onDidDismiss().then((data) => {
      const protagonist = data['data'] as FactPerson;
      if (protagonist) {
        const protagonistIndex = this.protagonists.findIndex((_protagonist) =>  _protagonist.id == protagonist.id);
        this.protagonists[protagonistIndex] = protagonist;

        const personIndex = this.persons.findIndex((_person) =>  _person.id == protagonist.id);
        this.persons[personIndex] = protagonist;

        this.updateForm.controls.persons.setValue(this.persons);
      }
    });

    await modal.present();
  }

  async onModifyGoodButtonClick(goodId: string) {
    const currentGood = this.goods.filter((_good) => _good.id == goodId)[0];
     const modal = await this.modalController.create({
      component: ModifyGoodModalComponent,
      componentProps : { currentGood }
    });

    modal.onDidDismiss().then((data) => {
      const good = data['data'] as FactGood;
      if (good) {
        const goodIndex = this.goods.findIndex((_good) =>  _good.id == good.id);
        this.goods[goodIndex] = good;

        this.updateForm.controls.goods.setValue(this.goods);
      }
    });

    await modal.present();
  }

  onRemoveProtagonistButtonClick(protagonistId) {
    if (!protagonistId) {
      return;
    }

    this.protagonists = this.protagonists.filter((_protagonist) => _protagonist.id != protagonistId);
    this.persons = this.persons.filter((_person) => _person.id != protagonistId);
    this.updateForm.controls.persons.setValue(this.persons);
  }

  async onAddGoodButtonClick() {
    const modal = await this.modalController.create({
      component: AddGoodModalComponent,
      cssClass: 'auto-height',
    });

    modal.onDidDismiss().then((data) => {
      const good = data['data'] as FactGood;
      if (good) {
        this.goods.push(good);
        this.updateForm.controls.goods.setValue(this.goods);
      }
    });

    await modal.present();
  }

  onRemoveGoodButtonClick(goodId) {
    if (!goodId) {
      return;
    }

    this.goods = this.goods.filter((_good) => _good.id != goodId);
    this.updateForm.controls.goods.setValue(this.goods);
  }

  async onAdd() {
    const modal = await this.modalController.create({
      component: AddPerpetratorModalComponent,
      cssClass: 'auto-height',
    });

    await modal.present();
  }

}
