<ion-content>
  <app-header></app-header>
  <main role="main">
    <app-breadcrumb></app-breadcrumb>
    <div class="fr-container--fluid fr-mb-2w fr-mb-md-0 sticky">
      <header role="banner" class="fr-header">
        <div class="fr-mx-7v">
          <nav class="fr-nav submenu" role="navigation" aria-label="Menu secondaire" data-fr-js-navigation="true">
            <div class="fr-grid-row fr-mx-n1w">
              <div class="fr-col-12 fr-col-md-4 fr-col-lg-3 fr-px-1w divider">
                <div class="submenu-logo"></div>
              </div>
              <div class="fr-col fr-col-lg-2 fr-px-1w">
                <button (click)="onCreateNetwork()" class="fr-nav__link fr-btn">
                  <span class="submenu-icon fr-icon-add-circle-fill white fr-mr-2v" aria-hidden="true"></span>
                  <span class="submenu-entry">Ajouter un utilisateur</span>
                  <span class="submenu-entry2">Ajouter</span>
                </button>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </div>
    <div class="fr-mx-7v">
      <div class="fr-grid-row">
        <div class="fr-col-12 fr-col-md-4 fr-col-lg-3">
          <nav class="fr-sidemenu fr-sidemenu--sticky-full-height fr-pr-0" role="navigation" aria-label="Menu latéral">
            <div class="fr-sidemenu__inner fr-container-left--responsive">
              <button class="fr-sidemenu__btn" aria-controls="fr-sidemenu-wrapper" aria-expanded="false"
                data-fr-js-collapse-button="true">Filtres
              </button>
              <div class="fr-collapse fr-pt-0 fr-pt-md-4w fr-pb-md-11w" id="fr-sidemenu-wrapper"
                data-fr-js-collapse="true">
                <div class="fr-sidemenu__title">Affiner la recherche</div>
                <button *ngIf="name" (click)="onReset()"
                  class="fr-btn fr-btn--sm fr-btn--icon-left fr-btn--secondary fr-fi-close-line fr-mb-4v">Réinitialiser</button>
                <div class="fr-mb-4w fr-mt-3v fr-mt-md-0">
                  <div class="fr-mb-3w">
                    <div class="fr-input-group">
                      <label class="fr-label" for="name">
                        Filtrer par nom
                      </label>
                      <input class="fr-input fr-text--sm" type="text" id="name" name="name" [(ngModel)]="name"
                        autocomplete="off">
                    </div>
                  </div>
                  <div class="fr-container--fluid">
                    <button (click)="onApply()" class="fr-btn fr-col-12 fr-center">Afficher les résultats</button>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <div class="fr-container-right--responsive fr-col-12 fr-col-md-8 fr-col-lg-9">
          <div class="fr-container--fluid" id="content">
            <section class="fr-grid-row fr-mt-4w">
              <div class="fr-col-12">
                <h2 class="fr-mt-0 fr-pb-4w fr-mb-0 divider">
                  Liste des utilisateurs ({{ userCount$ | async }})
                </h2>
              </div>
              <div class="fr-col-12">
                <div class="fr-table fr-table--bordered fr-table--condensed fr-table--no-caption fr-table--fit fr-mb-6v">
                  <table>
                    <caption>Liste des utilisateurs</caption>
                    <thead>
                      <tr>
                        <th scope="col">Prénom</th>
                        <th scope="col">Nom</th>
                        <th scope="col">Pseudo</th>
                        <th scope="col">Email</th>
                        <th scope="col">Qualité</th>
                        <th scope="col">Mis à jour le</th>
                        <th scope="col" class="fit"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngIf="!hasUsers">
                        <td colspan="7">Aucun utilisateur n'a été trouvé.</td>
                      </tr>
                      <tr
                        *ngFor="let user of users$ | async | paginate: { itemsPerPage: limit, currentPage: page, totalItems: userCount$ | async }">
                        <td>{{ user.firstname }}</td>
                        <td>{{ user.lastname }}</td>
                        <td>{{ user.username }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.role }}</td>
                        <td class="fit">{{ user.updatedAt | date:'dd/MM/yy à HH\'h\'mm' }}</td>
                        <td class="fit">
                          <button [disabled]="isManager && user.role === 'Responsable'"
                            (click)="onDeleteNetwork(user)"
                            class="fr-btn fr-btn--sm fr-icon-delete-line fr-btn--secondary fr-mr-2v"
                            title="Supprimer l'utilisateur">
                            Supprimer
                          </button>
                          <button (click)="onUpdateNetwork(user)"
                            class="fr-btn fr-btn--sm fr-icon-pencil-fill fr-btn--secondary" title="Modifier l'utilisateur">
                            Mettre à jour
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="fr-col-12">
                <nav role="navigation" class="fr-pagination fr-center" aria-label="Pagination">
                  <pagination-template #p="paginationApi" (pageChange)="onPageChanged($event)">
                    <ul class="fr-pagination__list fr-center">
                      <li *ngIf="!p.isFirstPage()">
                        <button (click)="p.previous()"
                          class="fr-pagination__link fr-pagination__link--prev fr-pagination__link--lg-label">
                          Page précédente
                        </button>
                      </li>
                      <li *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                        <button *ngIf="p.getCurrent() !== page.value" class="fr-pagination__link"
                          (click)="p.setCurrent(page.value)">
                          <span>{{ page.label }}</span>
                        </button>
                        <button *ngIf="p.getCurrent() === page.value" class="fr-pagination__link" aria-current="page">
                          <span>{{ page.label }}</span>
                        </button>
                      </li>
                      <li *ngIf="!p.isLastPage()">
                        <button
                          class="fr-pagination__link fr-pagination__link--next fr-pagination__link--lg-label pagination-next"
                          (click)="p.next()" [class.disabled]="p.isLastPage()">
                          Page suivante
                        </button>
                      </li>
                    </ul>
                  </pagination-template>
                </nav>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer></app-footer>
</ion-content>