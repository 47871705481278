import { Component } from '@angular/core';

@Component({
  selector: 'app-accessibility',
  templateUrl: './accessibility.page.html'
})
export class AccessibilityPage {

  constructor() { }

}
