import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Town } from '../models/town.model';

@Injectable({
  providedIn: 'root'
})
export class TownService {

  constructor(
    private httpClient: HttpClient) { }

  find(name: string): Observable<any> {
    return this.httpClient.get(`https://geo.api.gouv.fr/communes?fields=nom,code,codesPostaux,codeDepartement&nom=${name}`).pipe(
      switchMap((towns: Town[]) => of(towns.map(_town => {
        _town.codesPostaux.sort();
        const postalCode = _town.codesPostaux[0];
        _town.name = `${_town.nom} (${postalCode})`;
        _town.value = `${_town.nom} (${postalCode}) [${_town.code}]`;
        
        return _town;
      })))
    );
  }
}
