import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.state';
import { deleteUser } from 'src/app/store/user/user.actions';
import { User } from 'src/app/store/user/user.model';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
})
export class DeleteModalComponent {

  @Input() user: User;

  constructor(
    private store: Store<AppState>,
    private modalController: ModalController) {
  }

  onSubmit() {
    this.store.dispatch(deleteUser(this.user));
    this.modalController.dismiss();
  }

  onDismiss() {
    this.modalController.dismiss();
  }
}
